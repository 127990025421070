import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import styles from "./styles.module.css";
import herb from "../../../../assets/homeImages/herb.png";
import LiveAuctionCard from "../../../components/homeApp/liveAuctionCard/index";
import ButtonViewAll from "../../../components/homeApp/buttonViewAll";
import circleIcon from "../../../../assets/icons/Ellipse.svg";
import shiningIcon from "../../../../assets/icons/newShining.svg";
import linkIcon from "../../../../assets/icons/FiLink.svg";
import { Link } from "react-router-dom";
import LandingCarousel from "../../../components/homeApp/landingCarousel";
import empoweringFarmersImage from "../../../../assets/homeImages/Empowering_Farmers.jpg";
import traceabilityImage from "../../../../assets/homeImages/Traceability_and_transparency.jpg";
import sustainableImage from "../../../../assets/homeImages/Sustainable_and_ethical.jpg";
import qualityImage from "../../../../assets/homeImages/Quality_and_convenience.jpg";
import saphedMusli from "../../../../assets/homeImages/safed_musli.svg";
import moringa from "../../../../assets/homeImages/moringa.svg";
import rosemary from "../../../../assets/homeImages/rosemary.svg";
import basil from "../../../../assets/homeImages/basil.svg";
import tulsi from "../../../../assets/homeImages/tulsi.svg";
import shatavari from "../../../../assets/homeImages/shatavari.svg";
import ayushMinistryLogo from "../../../../assets/icons/ministryOfAyush.svg";
import indiaGovLogo from "../../../../assets/icons/indiaGov.svg";
import myGovLogo from "../../../../assets/icons/myGov.svg";
import dataGovLogo from "../../../../assets/icons/dataGov.svg";
import makeInIndiaLogo from "../../../../assets/icons/makeInIndia.svg";
import nationalMedicinalPlantBoard from "../../../../assets/icons/nationalMedicinalPlantsBoard.svg";
import { toast } from "react-toastify";
// import rawHerbPdf from "/pdf/Raw_Herbs_List.pdf";

// mocking backend data
const images = [];
for (let i = 0; i < 8; i++) {
  images.push({ url: herb, herbName: "herb name", price: 12000, unit: "kg" });
}

const officialLogos = [
  ayushMinistryLogo,
  indiaGovLogo,
  myGovLogo,
  dataGovLogo,
  makeInIndiaLogo,
  nationalMedicinalPlantBoard,
];

const defaultImage = herb;

const herbImages = [
  { imgUrl: saphedMusli, herbName: "Safed Musli" },
  { imgUrl: moringa, herbName: "Moringa" },
  { imgUrl: rosemary, herbName: "Rosemary" },
  { imgUrl: basil, herbName: "Basil" },
  { imgUrl: tulsi, herbName: "Tulsi" },
  { imgUrl: shatavari, herbName: "Shatavari" },
];

const newInformation = [
  { title: "The best way to have these herbs to reduce bloating", link: "#" },
  { title: "Best herb seeds to plant in spring", link: "#" },
  { title: "Boost Your Brain Power And Fight Diabetes", link: "#" },
  { title: "Pelicans' Guard Herb Jones Snubbed From NBA", link: "#" },
  { title: "Lion Order: Free The Herb", link: "#" },
  { title: "Herb Pots for Mother's Day", link: "#" },
  { title: "Sudan conflict: Indian herbal medicine sellers seek", link: "#" },
];

const relatedLinks = [
  { title: "The best way to have these herbs to reduce bloating", link: "#" },
  { title: "Best herb seeds to plant in spring", link: "#" },
  { title: "Boost Your Brain Power And Fight Diabetes", link: "#" },
  { title: "Pelicans' Guard Herb Jones Snubbed From NBA", link: "#" },
  { title: "Lion Order: Free The Herb", link: "#" },
  { title: "Herb Pots for Mother's Day", link: "#" },
  { title: "Sudan conflict: Indian herbal medicine sellers seek", link: "#" },
];

const carouselItems = [
  {
    image: empoweringFarmersImage,
    heading: "Empowering Farmers, Expanding Horizons",
    subHeading:
      "JadiButiBazar is committed to empowering farmers by providing them with a fair and transparent platform to sell their herbs.",
  },
  {
    image: traceabilityImage,
    heading: "Traceability and Transparency",
    subHeading:
      "JadiButiBazar uses state-of-the-art technology, including blockchain, to ensure traceability and transparency throughout the herb supply chain. This gives buyers confidence in the quality and authenticity of the herbs they purchase.",
  },
  {
    image: sustainableImage,
    heading: "Sustainable and Ethical",
    subHeading:
      "JadiButiBazar is committed to promoting sustainable and ethical practices in the herb trade. This helps to safeguard the environment and ensure that herbs are produced in a responsible way.",
  },
  {
    image: qualityImage,
    heading: "Quality and Convenience",
    subHeading:
      "JadiButiBazar provides end-to-end traceability and quality assurance for all herbs sold on the platform. This gives buyers peace of mind knowing that they are getting high-quality herbs that have been produced in a responsible way.",
  },
];

export default function Landing() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchLiveAuctions());
  }, [dispatch]);

  const recentLiveAuctions = useSelector(selectors.selectrecentauctions) || {
    auctions: [],
    totalRecords: 0,
  };

  let liveAuctions = recentLiveAuctions.auctions;
  return (
    <>
      <main>
        <LandingCarousel carouselItems={carouselItems} />
      </main>

      <section className={styles.liveAuctions}>
        <div className={styles.sectionHeader}>
          <h2>Recent live auctions</h2>
          <Link
            to={"/auctions"}
            style={{ color: "unset", textDecoration: "none" }}
          >
            {liveAuctions.length !== 0 && <ButtonViewAll />}
          </Link>
        </div>

        {liveAuctions.length !== 0 && (
          <div className={styles.liveAuctionCards}>
            {liveAuctions.map((herb, index) => (
              <LiveAuctionCard
                url={herb}
                herbName={herb.herbName}
                price={herb.askedPricePerUnit}
                unit={herb.quantityUnit}
                key={herb._id}
                auctionId={herb._id}
              />
            ))}
          </div>
        )}
        {liveAuctions.length === 0 && (
          <div className={styles.centerText}>No Live Auction available.</div>
        )}
      </section>
      <section className={styles.information}>
        <div className={styles.informationLeft}>
          <div className={styles.infoHeader}>
            <img src={shiningIcon} alt="new information icon" />
            <h2>What's new</h2>
          </div>
          <ul>
            {newInformation.map((info, index) => (
              <li key={index}>
                <img src={circleIcon} alt="list icon" />
                <Link to={info.link} rel="noreferrer" target="_blank">
                  {info.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.informationRight}>
          <div className={styles.infoHeader}>
            <img src={linkIcon} alt="related links icon" />
            <h2>Related links</h2>
          </div>
          <ul>
            {relatedLinks.map((info, index) => (
              <li key={index}>
                <img src={circleIcon} alt="list icon" />
                <Link to={info.link} rel="noreferrer" target="_blank">
                  {info.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className={styles.liveAuctions}>
        <div className={styles.sectionHeader}>
          <h2>Herbs</h2>
          <a href="/pdf/Raw_Herbs_List.pdf" target="_blank" rel="noreferrer">
            <ButtonViewAll />
          </a>
        </div>
        <div className={styles.herbList}>
          {herbImages.map((herb, index) => (
            <div key={index} className={styles.herbCard}>
              <div className={styles.herbImage}>
                <img src={herb.imgUrl} alt={herb.herbName} />
              </div>
              <p>{herb.herbName}</p>
            </div>
          ))}
        </div>
      </section>
      <section className={styles.logoList}>
        {officialLogos.map((herb, index) => (
          <div key={index} className={styles.logoContainer}>
            <img src={herb} alt="official ministry logo" />
          </div>
        ))}
      </section>
    </>
  );
}

import React, { useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import AuctionCard from "../../../components/shared/AuctionCard";
import styles from "./dashboard.module.css";
import Heading from "../../../components/shared/Heading";
import BuyerDoughnutChart from "../../../components/dashboardApp/BuyerDoughnutChart";
import DCard from "../../../components/shared/DCard";

import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import DoughnutChart from "../../../components/dashboardApp/DoughnutChart";
import { useAuth } from "../../../contexts/auth";

const Dashboard = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();
  const auth = useAuth();

  useEffect(() => {
    console.log("coming from buyer dashboard", auth.loggedInUser);
    dispatch(actions.fetchAuctionCount());
    dispatch(actions.fetchWonLost());
  }, [dispatch]);

  const auctionCounts = useSelector(selectors.selectauctioncount) || {
    all: 0,
    awarded: 0,
    bid_closed: 0,
    cancelled: 0,
    live: 0,
    lost: 0,
  };

  const wonlost = useSelector(selectors.selectwonlost) || {
    awarded: 0,
    lost: 0,
  };

  return (
    <>
      <section>
        <Container fluid>
          <Row>
            <Col lg={12} className={styles.marginBottom36}>
              <Heading text="Dashboard" />
            </Col>
          </Row>
          <Row className={styles.marginBottom36}>
            <Col lg={2} xs={3} className={styles.paddingLR}>
              <AuctionCard
                title="Total auctions"
                subtitle={auctionCounts.all}
              />
            </Col>
            <Col lg={2} xs={3}>
              <AuctionCard
                title="Live auctions"
                subtitle={auctionCounts.live}
              />
            </Col>
            <Col lg={2} xs={3}>
              <AuctionCard
                title="Won auctions"
                subtitle={auctionCounts.awarded}
              />
            </Col>
            <Col lg={2} xs={3}>
              <AuctionCard
                title="Lost auctions"
                subtitle={auctionCounts.lost}
              />
            </Col>
            <Col lg={2} xs={3}>
              <AuctionCard
                title="Pending awards"
                subtitle={auctionCounts.bid_closed}
              />
            </Col>
            <Col lg={2} xs={3}>
              <AuctionCard
                title="Canceled auctions"
                subtitle={auctionCounts.cancelled}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={5}>
              <DCard>
                <div style={{ padding: "15px 15px 0px 15px" }}>
                  <h3 className={styles.wonText}>Closed auctions</h3>
                  <DoughnutChart lbl={["Won", "Lost"]} won={wonlost.awarded} lost={wonlost.lost}/>
                  
                </div>
              </DCard>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;

import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import logos from "../../../../assets/icons/Logos.svg";
import openLogo from "../../../../assets/icons/Vector.svg";
import Button from "../../shared/button/index";
import Modal from "../modal";
import { useAuth } from "../../../contexts/auth";
import { Link } from "react-router-dom";
import routes from "../../../../routes";
import { ReactComponent as CloseMenu } from "../../../../assets/images/IoCloseOutline.svg";
import { ReactComponent as MenuIcon } from "../../../../assets/images/HiMenuAlt3.svg";

export default function Topbar() {
  const auth = useAuth();
  const user = auth.loggedInUser;

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const [openLinks, setOpenLinks] = useState(false);

  const linkRef = useRef(null);

  const getModalStyle = () => {
    
    // const height = linkRef.current.clientHeight;
    // const width = linkRef.current.clientWidth? linkRef.current.clientWidth: '14';

    // const offsetTop = linkRef.current.offsetTop;
    // const offsetLeft = linkRef.current.offsetLeft;
    // const left = offsetLeft + width / 2;
    // const top = offsetTop + height +40;
    const widthCheck = window.innerWidth <= 768;
    if(widthCheck){
      return {
        left: "51%",
        top: "26%",
        transform: "translate(-50%, 0px)",
        width: "45%",
        padding: "6px",
        borderRadius: "6px",
      };
    }else{
      return {
        left: `75%`,
        top: `20%`,
        transform: "translate(-50%, 0)",
        width: `14%`,
        padding: "6px",
        borderRadius: "6px",
      };
    }
    
  };

  const handleClose = () => {
    setOpenLinks(false);
  };
  const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

  return (
    <div className={styles.header}>
      <div className={styles.logoNav}>
          <div className={styles.logoContainer}>
            <Link to="/">
              <img src={logos} className={styles.logo} alt="logos" />
            </Link>
          </div>
        </div>
        <ul className={click ? styles.navOptions+' '+styles.active : styles.navOptions}>
          <li className={styles.option} onClick={closeMobileMenu}>
            <Link to="/auctions" className={styles.aUnset}>
              <span>Auctions</span>
            </Link>          
          </li>
          <li className={styles.option} onClick={closeMobileMenu}>
            <Link to="/about" className={styles.aUnset}>
              <span>About Us</span>
            </Link>
          </li>
          <li className={styles.option}>
            <span
              style={{ position: "relative" }}
              onClick={() => setOpenLinks(!openLinks)}
              ref={linkRef}
            >
              Knowledge Resources
              <button className={styles.olinks}>
                <img src={openLogo} alt="open" />
              </button>
              {openLinks ? (
                <Modal
                  className={styles.modalKnowledge}
                  modalStyle={getModalStyle()}
                  showModal={openLinks}
                  closeModal={handleClose}
                  closeButton={false}
                  enableBackground={true}
                >
                  <div className={styles.modalLinks}>
                    <Link
                      onClick={callAll(handleClose, closeMobileMenu)}
                      to="/knowMore/aboutMedicinalPlants"
                    >
                      Medicinal plants
                    </Link>
                    <Link onClick={callAll(handleClose, closeMobileMenu)} to="/knowMore/agroTech">
                      <span>Agro-techniques</span>
                    </Link>
                  </div>
                </Modal>
              ) : null}
            </span>
          </li>
        
      {user ? (
          <div className={styles.buttonContainer}>
            {user.role === "seller" && (
              <Link to="/dashboard/seller/landing">
                <Button outline text="Dashboard" />
              </Link>
            )}
            {user.role === "buyer" && (
              <Link to="/dashboard/buyer/landing">
                <Button outline text="Dashboard" />
              </Link>
            )}
            {user.role === "super_admin" && (
              <Link to={routes.dashboard.admin.auctions}>
                <Button outline text="Dashboard" />
              </Link>
            )}
            <Button solid text="Logout" buttonClick={auth.logout} />
          </div>
        ) : (<>
            <li className={styles.signIn} style={{ marginTop: openLinks ? "6.5rem" : "0rem" }} onClick={closeMobileMenu}>
              <Link className={styles.aUnset} to="/login?l=t">
                <Button outline text="Login" />
              </Link>
            </li>
            <li onClick={closeMobileMenu}>
              <Link className={styles.aUnset} to="/register?l=t">
                <Button solid text="Register" />
              </Link>
            </li>
          </>
        )}
      </ul>
      <div className={styles.mobileMenu} onClick={handleClick}>
        {click ? (
          <CloseMenu className={styles.menuIcon} />
        ) : (
          <MenuIcon className={styles.menuIcon} />
        )}
      </div>
    </div>
  );
}

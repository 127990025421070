import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import FileIcon from "../../../../../assets/icons/file.svg";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import SelectCountry from "../../../../components/shared/select/selectCountry";
import SelectState from "../../../../components/shared/select/selectState";
import SelectCity from "../../../../components/shared/select/selectCity";

const inputStyles = {
  height: "16px",
  width: "251px",
  borderRadius: "6px",
};
const selectStyles = {
  select: {
    borderRadius: "8px",
    height: "48px",
    width: "276px",
  },
};
const containerStyle = {
  border: "2px dashed #E2E8F0",
  backgroundColor: "#F7FAFC",
  height: "98px",
  paddingLeft: "150px",
  paddingTop: "25px",
  borderRadius: "6px",
};
const fileContainerStyle = {
  border: "1px solid #EDF2F7",
  borderRadius: "6px",
  height: "48px",
  paddingTop: "12px",
  paddingLeft: "15px",
  paddingRight: "15px",
};
const backButtonStyles = {
  root: {
    width: "276px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "#FFFFFF !important",
    border: "1px solid #E2E8F0 !important",
    boxShadow: "none !important",
  },
};

const verifyButtonStyles = {
  root: {
    width: "276px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "green !important",
  },
};

const mbinputStyles = {
  height: "16px",
  width: "100%",
  borderRadius: "6px",
};
const mbselectStyles = {
  select: {
    borderRadius: "8px",
    height: "48px",
    width: "276px",
  },
};
const mbcontainerStyle = {
  border: "2px dashed #E2E8F0",
  backgroundColor: "#F7FAFC",
  height: "98px",
  paddingLeft: "50px",
  paddingTop: "25px",
  borderRadius: "6px",
};
const mbfileContainerStyle = {
  border: "1px solid #EDF2F7",
  borderRadius: "6px",
  height: "48px",
  paddingTop: "12px",
  paddingLeft: "15px",
  paddingRight: "15px",
};
const mbackButtonStyles = {
  root: {
    width: "150px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "#FFFFFF !important",
    border: "1px solid #E2E8F0 !important",
    boxShadow: "none !important",
  },
};

const mbverifyButtonStyles = {
  root: {
    width: "150px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "green !important",
  },
};

export default function AddressDetails(props) {
  const [country, setCountry] = React.useState({ label: "India", id: "" });
  const [state, setState] = useState({ label: "", id: "" });
  const [city, setCity] = useState({ label: "", id: "" });
  const [pinCode, setPinCode] = useState("");
  const [address, setAddress] = useState("");
  const [fileToUpload, setFileToUplopad] = useState(null);
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [fileToUploadError, setFileToUplopadError] = useState(null);

  const widthCheck = window.innerWidth <= 768;

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setAddressError("");
  };
  const handleCountryChange = (event, value) => {
    setCountry(value);
    setCountryError("");
  };
  const handleStateChange = (event, value) => {
    setState(value);
    setStateError("");
  };
  const handleCityChange = (event, value) => {
    setCity(value);
    setCityError("");
  };
  const handlePinCodeChange = (event) => {
    setPinCode(event.target.value);
    setPinCodeError("");
  };
  const handleFileChange = (e) => {
    const file = e.target.files;
    setFileToUplopad(file);
    setFileToUplopadError(null);
  };
  const handleInputClick = () => {
    document.getElementById("input_file").click();
  };

  const CustomButton = withStyles(widthCheck ? mbackButtonStyles : backButtonStyles)(({ classes, ...props }) => (
    <Button className={classes.root} {...props} />
  ));

  const CustomVerifyButton = withStyles(widthCheck ? mbverifyButtonStyles : verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );
  const CustomSelect = withStyles(widthCheck ? mbselectStyles : selectStyles)(({ classes, ...props }) => (
    <Select className={classes.select} {...props} />
  ));

  const checkAddress = (address) => {
    const pattern = /^[#,\s\w]+$/;
    return pattern.test(address);
  };

  const checkPincode = (pincode) => {
    const pattern = /^\d{6}$/;
    return pattern.test(pincode);
  };

  const handleSubmit = () => {
    if (address.trim() === "") {
      setAddressError("Address is required.");
      // return;
    } else {
      if (checkAddress(address.trim())) {
        setAddressError("");
      } else {
        setAddressError(
          "Invalid address. Only letters, spaces, '#' and ',' characters are allowed."
        );
        return;
      }
    }
    if (country.label.trim() === "") {
      setCountryError("Country is required.");
      // return;
    }
    if (state.label.trim() === "") {
      setStateError("State is required.");
      // return;
    }
    if (city.label.trim() === "") {
      setCityError("City is required.");
      // return;
    }
    if (pinCode.trim() === "") {
      setPinCodeError("Pincode is required.");
      return;
    } else {
      if (checkPincode(pinCode.trim())) {
        setPinCodeError("");
      } else {
        setPinCodeError("Invalid PIN code. Please enter a 6-digit PIN code.");
        return;
      }
    }
    if (
      address !== "" ||
      country.label !== "" ||
      state.label !== "" ||
      city.label !== "" ||
      pinCode !== ""
    ) {
      props.onClickNextButton(
        address,
        country.label,
        state.label,
        city.label,
        pinCode,
        fileToUpload
      );
    }
  };
  const countryD = { display: "flex", gap: 32, marginTop: "20px" };
  const mbCountryD = { gap: 32, marginTop: "20px" };
  return (
    <>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
        }}
      >
        Address details
      </div>
      <div
        style={{
          color: "#718096",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          marginTop: "8px",
        }}
      >
        Enter your address details
      </div>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "32px",
        }}
      >
        Address
      </div>
      <div style={{ marginTop: "8px" }}>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          placeholder="#123, ABC Colony"
          value={address}
          onChange={handleAddressChange}
          inputProps={{
            maxLength: 100,
          }}
          style={{
            width: "584px",
            height: "40px !important",
            borderRadius: "6px",
          }}
        />
        {addressError && <FormHelperText error>{addressError}</FormHelperText>}
      </div>
      <div style={widthCheck ? mbCountryD : countryD}>
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          ></div>
          <div style={{width: "276px"}}>
            {/* <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. India"
              inputProps={{
                style: inputStyles,
                maxLength: 50,
              }}
              value={country}
              onChange={handleCountryChange}
            /> */}
            <SelectCountry
              countryName="India"
              handleCountryChange={handleCountryChange}
            />
            {countryError && (
              <FormHelperText error>{countryError}</FormHelperText>
            )}
          </div>
        </div>
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          ></div>
          <div style={{width: "276px"}}>
            {/* <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. Odisha"
              inputProps={{
                style: inputStyles,
                maxLength: 50,
              }}
              value={state}
              onChange={handleStateChange}
            /> */}
            <SelectState
              country={country}
              state={state}
              handleStateChange={handleStateChange}
            />
            {stateError && <FormHelperText error>{stateError}</FormHelperText>}
          </div>
        </div>
      </div>
      <div style={widthCheck ? mbCountryD : countryD}>
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          ></div>
          <div style={{width: "276px"}}>
            {/* <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. Sundergarh"
              inputProps={{
                style: inputStyles,
                maxLength: 50,
              }}
              value={city}
              onChange={handleCityChange}
            /> */}
            <SelectCity
              state={state}
              city={city}
              handleCityChange={handleCityChange}
            />
            {cityError && <FormHelperText error>{cityError}</FormHelperText>}
          </div>
        </div>
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            PIN code
          </div>
          <div>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. xxxxxx"
              inputProps={{
                style: widthCheck ? mbinputStyles : inputStyles,
                maxLength: 6,
              }}
              value={pinCode}
              onChange={handlePinCodeChange}
            />
            {pinCodeError && (
              <FormHelperText error>{pinCodeError}</FormHelperText>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "20px",
        }}
      >
        Upload an address proof
      </div>
      <div style={{ marginTop: "8px" }}>
        <Box style={widthCheck? mbcontainerStyle: containerStyle}>
          <input
            type="file"
            name=""
            id="input_file"
            style={{ display: "none" }}
            //ref={fileRef}
            onChange={handleFileChange}
            accept={".pdf" /*, .zip, .png, .jpeg*/}
          />
          <div style={{ display: "flex", gap: 2, marginLeft: "36px" }}>
            <div
              style={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Drag and drop files or
            </div>
            <div
              style={{
                color: "#38A169",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
              onClick={handleInputClick}
            >
              Browse
            </div>
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
            }}
          >
            Supported formats: JPEG, JPG, PNG, PDF
          </div>
        </Box>
        {fileToUpload && (
          <div style={{ marginTop: "8px" }}>
            <Box style={widthCheck ? mbfileContainerStyle:fileContainerStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 4 }}>
                  <div>
                    <img src={FileIcon} alt="file" />
                  </div>

                  <div
                    style={{
                      color: "#1A202C",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "2px",
                    }}
                  >
                    {fileToUpload[0].name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setFileToUplopad(null);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>
      <div style={{ display: "flex", gap: 40, marginTop: "160px" }}>
        <div>
          <CustomButton onClick={props.onClickBackButton} variant="contained">
            <div
              style={{
                color: "#1A202C",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Back
            </div>
          </CustomButton>
        </div>
        <div>
          <CustomVerifyButton onClick={handleSubmit}>
            <div
              style={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Next
            </div>
          </CustomVerifyButton>
        </div>
      </div>
    </>
  );
}

import { format, parseISO } from "date-fns";

export const highestBid = (bids) => {
  if (!bids.length) return null;
  const max = bids.reduce(function (prev, current) {
    return prev.amount > current.amount ? prev : current;
  }); //returns object
  return max;
};

export const myHighestBid = (bids, userId) => {
  if (!bids.length) return null;
  let myBids = filterMyBids(bids, userId);
  if (!myBids || !myBids.length) return null;
  const max = myBids.reduce(function (prev, current) {
    return prev.amount > current.amount ? prev : current;
  }); //returns object
  return max;
};

export const filterMyBids = (bids, userId) => {
  if (!bids || !bids.length) return [];
  if (bids[0].placedBy && bids[0].placedBy._id) {
    let myBids = bids.filter((b) => b.placedBy._id === userId);
    return myBids;
  } else {
    let myBids = bids.filter((b) => b.placedBy === userId);
    return myBids;
  }
};

export const formattedDate = (dateString) => {
  if (!isDate(dateString)) return null;
  const date = new Date(dateString);
  return (
    date.toLocaleDateString("en-US", { day: "numeric" }) +
    "-" +
    date.toLocaleDateString("en-US", { month: "short" }) +
    "-" +
    date.toLocaleDateString("en-US", { year: "numeric" })
  );
};

export const formattedDateForTrace = (dateString) => {
  if (!isDate(dateString)) return null;
  const date = new Date(dateString);
  
  return (
    date.toLocaleDateString("en-US", { day: "numeric" }) +
    "-" +
    date.toLocaleDateString("en-US", { month: "short" }) +
    "-" +
    date.toLocaleDateString("en-US", { year: "numeric" })
  );

  

  
};

export const getBuyerId = () => {
  return "6465cf53aaa605f8c2a0adbd";
};

var isDate = function (date) {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};

export const formattedDateTime = (dateString) => {
  if (!isDate(dateString)) return null;
  const date = new Date(dateString);
  return date.toLocaleString("es-CL");
  //return  date.toLocaleDateString("en-US", { day: 'numeric' }) + "-"+ date.toLocaleDateString("en-US", { month: 'short' })+ "-" + date.toLocaleDateString("en-US", { year: 'numeric' })
};

export const formatDateForMyBids = (date) => {
  return format(parseISO(date), "MMMM d, yyyy h:m aaa");
};

export const formatDateForAuctionDetail = (date) => {
  return format(parseISO(date), "MMMM d, yyyy");
};

export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export function formatedAuctionId(id) {
  if(!id){
    return ' Auction ';
  }
  let idString = id.toString();
  let n = 5 - idString.length;
  for (let i = 0; i < n; i++) {
    idString = "0" + idString;
  }
  return "AUC-" + idString;
}

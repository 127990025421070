import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import AuctionCard from "../../../components/shared/AuctionCard";
import styles from "./dashboard.module.css";
import Heading from "../../../components/shared/Heading";
import DoughnutChart from "../../../components/dashboardApp/DoughnutChart";
import DCard from "../../../components/shared/DCard";
import DateInput from "../../../components/shared/DateInput";

import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";

const Dashboard = () => {
    
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: labListingSaga });
    const dispatch = useDispatch();
  
    useEffect(() => {
        
      dispatch(actions.fetchAuctionCount());
      dispatch(actions.fetchClosedAuctions());
    }, [dispatch]);

    const auctionCount = useSelector(selectors.selectauctioncount) || {all:0,awarded:0,bid_closed:0,cancelled:0,live:0,review:0};    
    const closedAuctions =useSelector(selectors.selectclosedauction) || {awarded:0,bid_closed:0};
  return (
    <>
        <section>
            <Container fluid>
                <Row>
                    <Col lg={12} className={styles.marginBottom36}><Heading text="Dashboard"/></Col>
                    {/* <Col lg={4}><DateInput /></Col>                     */}
                </Row>
                <Row className={styles.marginBottom36}>
                    <Col xs={3} className={[styles.paddingLR, styles.colcustom3].join(' ')}><AuctionCard title="Live auctions" subtitle={auctionCount.live} /></Col>
                    <Col xs={3} className={styles.colcustom3}><AuctionCard title="Awarded auctions" subtitle={auctionCount.awarded} /></Col>
                    <Col xs={3} className={styles.colcustom3}><AuctionCard title="Pending awards" subtitle={auctionCount.bid_closed} /></Col>
                    <Col xs={3} className={styles.colcustom3}><AuctionCard title="In-review auctions" subtitle={auctionCount.review} /></Col>
                    <Col xs={3} className={styles.colcustom3}><AuctionCard title="Cancelled auctions" subtitle={auctionCount.cancelled} /></Col>
                </Row>
                <Row>
                    <Col lg={4} md={5}>
                        <DCard>
                            <div style={{padding: '15px 15px 0px 15px'}}>
                                <h3 className={styles.wonText}>Closed auctions</h3>
                                <DoughnutChart lbl={['Awarded', 'Pending']} won={closedAuctions.awarded} lost={closedAuctions.bid_closed} />
                            </div>
                        </DCard>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  );
};

export default Dashboard;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { regionSaga } from "./saga";
import { sliceKey, reducer, actions } from "./slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "./selectors";

export default function SelectState({
  country,
  state,
  handleStateChange,
  bootstrap,
  stateError,
}) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: regionSaga });

  const states = useSelector(selectors.states) || [];

  const dispatch = useDispatch();
//console.log(state, 'state')
  useEffect(() => {
    dispatch(actions.fetchStates("6139b09a34b4734fa66607ff"));
  }, [dispatch]);

  return (
    <div>
      {!bootstrap ? (
        <>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              padding: "10px",
            }}
          ></div>
          <div>
            <Autocomplete
              disablePortal
              disableClearable
              value={state}
              id="states"
              options={states}
              sx={{ width: "100%" }}
              onChange={handleStateChange}
              renderInput={(params) => (
                <TextField {...params} label="State" value={state.label} />
              )}
            />
          </div>
        </>
      ) : (
        <>
          <Typeahead
            id="states"
            options={states}
            labelKey="label"
            onChange={handleStateChange}
            placeholder="Select State"
            isInvalid={stateError ? stateError : false}
            defaultSelected={state ? [state] : []}
          >
            {(props) => (
              <Form.Control
                {...props}
                label="State"
                name="state"
                style={{ display: "none" }}
                value={state ? state.label : ""}
              />
            )}
          </Typeahead>
          <Form.Control.Feedback type="invalid">
            {!!stateError && stateError}
          </Form.Control.Feedback>
        </>
      )}
    </div>
  );
}

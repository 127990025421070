import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Form } from "react-bootstrap";
import { regionSaga } from "./saga";
import { sliceKey, reducer, actions } from "./slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "./selectors";

export default function SelectCity({
  state,
  city,
  handleCityChange,
  bootstrap,
  districtError,
}) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: regionSaga });

  const states = useSelector(selectors.states);

  const cities = useSelector(selectors.cities) || [];

  const [stateId, setStateId] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (states && states.length) {
      const newStateId = states.reduce((acc, cv) => {
        if (cv.label === state.label) {
          acc = cv.id;
        }
        return acc;
      }, "");

      console.log(newStateId);
      setStateId(newStateId);
      // dispatch(actions.fetchCities(newStateId));
    }
  }, [dispatch, states, state]);

  useEffect(() => {
    dispatch(actions.fetchCities(stateId));
  }, [dispatch, stateId]);

  return (
    <div>
      {!bootstrap ? (
        <>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              padding: "10px",
            }}
          ></div>
          <div>
            <Autocomplete
              disablePortal
              disableClearable
              value={city}
              id="cities"
              options={cities}
              sx={{ width: "100%" }}
              onChange={handleCityChange}
              renderInput={(params) => (
                <TextField {...params} label="City" value={city.label} />
              )}
            />
          </div>
        </>
      ) : (
        <>
          <Typeahead
            id="cities"
            options={cities}
            labelKey="label"
            onChange={handleCityChange}
            placeholder="Select District"
            isInvalid={districtError ? districtError : false}
            defaultSelected={city ? [city] : []}
          >
            {(props) => (
              <Form.Control
                {...props}
                label="District"
                name="district"
                style={{ display: "none" }}
                value={city ? city.label : ""}
              />
            )}
          </Typeahead>
          <Form.Control.Feedback type="invalid">
            {!!districtError && districtError}
          </Form.Control.Feedback>
        </>
      )}
    </div>
  );
}

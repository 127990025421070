import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import FileIcon from "../../../../../assets/icons/file.svg";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";

export default function BankDetails(props) {
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [fileToUpload, setFileToUplopad] = useState(null);
  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [accountHolderError, setAccountHolderError] = useState("");

  const handleBankChange = (event) => {
    setBankName(event.target.value);
    setBankNameError('');
  };
  const handleBranchChange = (event) => {
    setBranchName(event.target.value);
    setBranchNameError('');
  };
  const handleAccountChange = (event) => {
    setAccountNumber(event.target.value);
    setAccountNumberError('');
  };
  const handleIfscCodeChange = (event) => {
    setIfscCode(event.target.value);
    setIfscCodeError('');
  };
  const handleAccountHolderChange = (event) => {
    setAccountHolder(event.target.value);
    setAccountHolderError('');
  };
  const handleInputClick = () => {
    document.getElementById("input_file").click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files;
    setFileToUplopad(file);
  };
  const checkName = (name) => {
    const pattern = /^[A-Za-z\s]+$/;
    return pattern.test(name);
  };
  const checkAccountNumber = (accountNumber) => {
    const pattern = /^\d+$/; // Regular expression to check if the input consists of only numeric digits
    return accountNumber.length > 0 && pattern.test(accountNumber);
  };
  const checkIFSCCode = (ifscCode) => {
    const pattern = /^[A-Za-z]{4}[0]{1}[A-Za-z0-9]{6}$/;
    return pattern.test(ifscCode);
  };
  const handleSubmit = (event) => {
    if (bankName.trim() === '') {
      setBankNameError('Bank Name is required.')
    }else{      
      if(checkName(bankName.trim())){          
        setBankNameError("");
      }else{          
        setBankNameError("Enter only letters.");
        return;
      }
    }
    if (branchName.trim() === '') {
      setBranchNameError('Branch Name is required.')
    }else{      
      if(checkName(branchName.trim())){          
        setBranchNameError("");
      }else{          
        setBranchNameError("Enter only letters.");
        return;
      }
    }
    if (ifscCode.trim() === '') {
      setIfscCodeError('IFSC Code is required.')
    }
    else{
      if(checkIFSCCode(ifscCode.trim())){
        setIfscCodeError("");
      }else{
        setIfscCodeError("Invalid IFSC code. Please enter a valid 11-character IFSC code.");
        return;
      }
    }
    if (accountNumber.trim() === '') {
      setAccountNumberError('Account Number is required.')
    }else{
      if(checkAccountNumber(accountNumber.trim())){
        setAccountNumberError("");
      }else{
        setAccountNumberError("Invalid bank account number. Please enter a valid account number.");
        return;
      }
    }
    if (accountHolder.trim() === '') {
      setAccountHolderError('Account Holder name is required.');
      return;
    }else{      
      if(checkName(accountHolder.trim())){          
        setAccountHolderError("");
      }else{          
        setAccountHolderError("Enter only letters.");
        return;
      }
    }
    if(bankName !== '' || branchName !== '' || ifscCode !== '' || accountNumber !== '' || accountHolder !== ''){
      props.onClickFinishbutton(
        bankName,
        branchName,
        ifscCode,
        accountNumber,
        accountHolder,
        fileToUpload
      );
      console.log("submit");
    }
  };
  const containerStyle = {
    border: "2px dashed #E2E8F0",
    backgroundColor: "#F7FAFC",
    height: "98px",
    paddingLeft: "150px",
    paddingTop: "25px",
    borderRadius: "6px",
  };
  const fileContainerStyle = {
    border: "1px solid #EDF2F7",
    borderRadius: "6px",
    height: "48px",
    paddingTop: "12px",
    paddingLeft: "15px",
    paddingRight: "15px",
  };
  const inputStyles = {
    height: "16px",
    width: "251px",
    borderRadius: "6px",
  };
  const backButtonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "#FFFFFF !important",
      border: "1px solid #E2E8F0 !important",
      boxShadow: "none !important",
    },
  };

  const verifyButtonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "green !important",
    },
  };
  const mbcontainerStyle = {
    border: "2px dashed #E2E8F0",
    backgroundColor: "#F7FAFC",
    height: "98px",
    paddingLeft: "50px",
    paddingTop: "25px",
    borderRadius: "6px",
  };
  const mbinputStyles = {
    height: "16px",
    width: "100%",
    borderRadius: "6px",
  };
  const mbbackButtonStyles = {
    root: {
      width: "150px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "#FFFFFF !important",
      border: "1px solid #E2E8F0 !important",
      boxShadow: "none !important",
    },
  };

  const mbverifyButtonStyles = {
    root: {
      width: "150px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "green !important",
    },
  };
  const widthCheck = window.innerWidth <= 768;
  const CustomButton = withStyles(widthCheck? mbbackButtonStyles:backButtonStyles)(({ classes, ...props }) => (
    <Button className={classes.root} {...props} />
  ));

  const CustomVerifyButton = withStyles(widthCheck? mbverifyButtonStyles:verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );
  const bankD= {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "32px",
  };
  const mbBankD = {
    justifyContent: "space-between",
    marginTop: "32px",
  };
  const accountD ={
    display: "flex",
    justifyContent: "space-between",
    marginTop: "32px",
  };
  const mbAccountD = {
    justifyContent: "space-between",
    marginTop: "32px",
  };
  return (
    <>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
        }}
      >
        Bank details
      </div>
      <div
        style={{
          color: "#718096",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          marginTop: "8px",
        }}
      >
        Enter your bank details
      </div>
      <div
        style={widthCheck? mbBankD: bankD}
      >
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Bank name
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. ABC Bank"
              inputProps={{
                style: widthCheck? mbinputStyles:inputStyles,
                maxLength: 50
              }}
              value={bankName}
              onChange={handleBankChange}
            />
            { bankNameError && <FormHelperText error>{bankNameError}</FormHelperText>}
          </div>
        </div>
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Branch name
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Branch"
              inputProps={{
                style: inputStyles,
                maxLength: 50
              }}
              value={branchName}
              onChange={handleBranchChange}
            />
            { branchNameError && <FormHelperText error>{branchNameError}</FormHelperText>}
          </div>
        </div>
      </div>
      <div
        style={widthCheck? mbAccountD: accountD}
      >
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Account number
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. 1234XXXXXX"
              inputProps={{
                style: widthCheck? mbinputStyles:inputStyles,
                maxLength: 17
              }}
              value={accountNumber}
              onChange={handleAccountChange}
            />
            { accountNumberError && <FormHelperText error>{accountNumberError}</FormHelperText>}
          </div>
        </div>
        <div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            IFSC Code
          </div>
          <div style={{ marginTop: "8px" }}>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Ex. ABCD1XXXXXX"
              inputProps={{
                style: widthCheck? mbinputStyles:inputStyles,
                maxLength: 11
              }}
              value={ifscCode}
              onChange={handleIfscCodeChange}
            />
            { ifscCodeError && <FormHelperText error>{ifscCodeError}</FormHelperText>}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            color: "#2D3748",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Account holder name
        </div>
        <div style={{ marginTop: "8px" }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            placeholder="Ex. Amit Singh"
            inputProps={{
              style: inputStyles,
              maxLength: 50
            }}
            value={accountHolder}
            onChange={handleAccountHolderChange}
          />
          { accountHolderError && <FormHelperText error>{accountHolderError}</FormHelperText>}
        </div>
      </div>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "20px",
        }}
      >
        Upload canceled cheque
      </div>
      <div style={{ marginTop: "8px" }}>
        <Box style={widthCheck? mbcontainerStyle:containerStyle}>
          <input
            type="file"
            name=""
            id="input_file"
            style={{ display: "none" }}
            //ref={fileRef}
            onChange={handleFileChange}
            accept={".pdf"/*, .zip, .png, .jpeg*/}
          />
          <div style={{ display: "flex", gap: 2, marginLeft: "36px" }}>
            <div
              style={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Drag and drop files or
            </div>
            <div
              style={{
                color: "#38A169",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
              onClick={handleInputClick}
            >
              Browse
            </div>
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
            }}
          >
            Supported formats: JPEG, JPG, PNG, PDF
          </div>
        </Box>
        {fileToUpload && (
          <div style={{ marginTop: "8px" }}>
            <Box style={fileContainerStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 4 }}>
                  <div>
                    <img src={FileIcon} alt="file" />
                  </div>

                  <div
                    style={{
                      color: "#1A202C",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "2px",
                    }}
                  >
                    {fileToUpload[0].name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setFileToUplopad(null);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>
      <div style={{ display: "flex", gap: 40, marginTop: "160px" }}>
        <div>
          <CustomButton onClick={props.onClickBackbutton} variant="contained">
            <div
              style={{
                color: "#1A202C",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Back
            </div>
          </CustomButton>
        </div>
        <div>
          <CustomVerifyButton onClick={handleSubmit}>
            <div
              style={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Finish
            </div>
          </CustomVerifyButton>
        </div>
      </div>
    </>
  );
}
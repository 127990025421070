import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import styles from "./style.module.css";

const HerbalDetailForm = ({
  formData,
  handleInputChange,
  saveHerbalDetails,
}) => {
  const [herbNameError, setHerbNameError] = useState(null);
  const [varietyError, setVarietyError] = useState(null);
  const [quantityError, setQuantityError] = useState(null);
  const [askedPriceError, setAskedPriceError] = useState(null);
  const [packSizeError, setPackSizeError] = useState(null);
  const [ptypeError, setPTypeError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  const [formValidated, setFormValidated] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [submitDisabled, setsubmitDisabled] = useState(null);
  const [numericError, setNumericError] = useState(false);
  const [count, setCount] = useState(0);

  const [errorContainer,seterrorContainer] = useState({
    herbName:'',
    variety:'',
    quantity:'',
    askedPrice:'',
    packSize:'',
    ptype:'',
    description:''
  });

  const packagingTypes = [
    { value: "", label: "Select Packaging Type" },
    { value: "Gunny bags", label: "Gunny bags" },
    { value: "PP bags", label: "PP bags" },
    { value: "Loose", label: "Loose" },
    { value: "Others", label: "Others" },
  ];

  const maxlengthofTextBox = (event) => {
    const { value } = event.target;
    setCount(value.length);
    handleInputChange(event);
  };
  const findNumericAndDecimal = (event) => {
    const { value } = event.target;
    if (value === "" || /^\d+(\.\d+)?$/.test(value)) {
      if (event.target.name === "packSize") {
        setPackSizeError("");
      }
      if (event.target.name === "quantity") {
        setQuantityError("");
      }
      if (event.target.name === "askedPrice") {
        setAskedPriceError("");
      }
      handleInputChange(event);
    } else {
      if (event.target.name === "packSize") {
        setPackSizeError("Please enter a numeric value.");
      }
      if (event.target.name === "quantity") {
        setQuantityError("Please enter a numeric value.");
      }
      if (event.target.name === "askedPrice") {
        setAskedPriceError("Please enter a numeric value.");
      }
    }
  };

  const handleNextButton = (event) => {
    if (!formData.herbName) {
      setHerbNameError("Please enter a herb name.");
      seterrorContainer({herbName:"Please enter a herb name."});
    } else {
      setHerbNameError(null);
    }
    if (!formData.variety) {
      setVarietyError("Please enter a variety.");
      seterrorContainer({variety:"Please enter a variety."});
    } else {
      setVarietyError(null);
    }
    if (!formData.quantity) {
      setQuantityError("Please enter a quantity.");
    } else {
      setQuantityError(null);
    }
    if (!formData.askedPrice) {
      setAskedPriceError("Please enter an ask price.");
    } else {
      setAskedPriceError(null);
    }
    if (!formData.packSize) {
      setPackSizeError("Please enter a packaging size.");
    } else {
      setPackSizeError(null);
    }
    if (!formData.ptype || formData.ptype === "") {
      setPTypeError("Please select a packaging type.");
    } else {
      setPTypeError(null);
    }
    if (!formData.description) {
      setDescriptionError("Please enter a grade description.");
    } else if (count > 500) {
      setDescriptionError("Description should be of 500 characters only");
    } else {
      setDescriptionError(null);
    }

    // if(count > 500){
    //   setDescriptionError("Description should be of 500 characters only");
    // } else {
    //   setDescriptionError(null);
    // }
    // setErrorMessage();
    if (
      formData.herbName &&
      formData.variety &&
      formData.quantity &&
      formData.askedPrice &&
      formData.packSize &&
      formData.ptype &&
      formData.description &&
      count <= 500
    ) {
      // Form is valid, perform submission
      saveHerbalDetails();
    }
  };

  const localhandleOnblur = (event) => {
    setErrorMessage(event.target.id);
  };

  const setErrorMessage = (id) => {    
    switch (id) {
      case "herbName":
        if (!formData.herbName) {
          setHerbNameError("Please enter a herb name.");
        } else {
          setHerbNameError(false);
        }
        break;
      case "variety":
        if (!formData.variety) {
          setVarietyError("Please enter a variety.");
        } else {
          setVarietyError(null);
        }
        break;
      case "quantity":
        if (!formData.quantity) {
          setQuantityError("Please enter a quantity.");
        } else {
          setQuantityError(null);
        }
        break;
      case "askedPrice":
        if (!formData.askedPrice) {
          setAskedPriceError("Please enter an ask price.");
        } else {
          setAskedPriceError(null);
        }
        break;

      case "packSize":
        if (!formData.packSize) {
          setPackSizeError("Please enter a packaging size.");
        } else {
          setPackSizeError(null);
        }
        break;
      case "ptype":
        if (!formData.ptype || formData.ptype === "") {
          setPTypeError("Please select a packaging type.");
        } else {
          setPTypeError(null);
        }
        break;
        case "description":
          if (!formData.description) {
            setDescriptionError("Please enter a grade description.");
          } else if (count > 500) {
            setDescriptionError("Description should be of 500 characters only");
          } else {
            setDescriptionError(null);
          }
        break;
    }
    
  };

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <h3 className="formheadingText">Herb details</h3>
      <Form noValidate validated={formValidated}>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="herbName">
              <Form.Label>
                <span className="headingText">Herb name</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="herbName"
                className={`form-control ${herbNameError && "is-invalid"} ${
                  herbNameError === false && "is-valid"
                }`}
                required
                maxLength={255}
                value={formData.herbName}
                onChange={handleInputChange}
                onBlur={localhandleOnblur}
              />
              <small className={`${herbNameError && "invalid-feedback"} `}>
                {!!herbNameError && herbNameError}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="variety">
              <Form.Label>
                <span className="headingText">Variety</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="variety"
                maxLength={255}
                className={`form-control ${varietyError && "is-invalid"} ${
                  varietyError === false && "is-valid"
                }`}
                value={formData.variety}
                onChange={handleInputChange}
                onBlur={localhandleOnblur}
                required
              />
              <small className={`${varietyError && "invalid-feedback"} `}>
                {!!varietyError && varietyError}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="quantity">
              <Form.Label>
                <span className="headingText">Total quantity(Kg)</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="quantity"
                value={formData.quantity}
                onChange={findNumericAndDecimal}
                onBlur={localhandleOnblur}
                maxLength={10}
                className={`form-control ${quantityError && "is-invalid"} ${
                  quantityError === false && "is-valid"
                }`}
                required
              />
              <small className={`${quantityError && "invalid-feedback"} `}>
                {!!quantityError && quantityError}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="askedPrice">
              <Form.Label>
                <span className="headingText">Ask price(Kg)</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="askedPrice"
                value={formData.askedPrice}
                onChange={findNumericAndDecimal}
                onBlur={localhandleOnblur}
                maxLength={10}
                className={`form-control ${askedPriceError && "is-invalid"} ${
                  askedPriceError === false && "is-valid"
                }`}
                required
              />
              <small className={`${askedPriceError && "invalid-feedback"} `}>
                {!!askedPriceError && askedPriceError}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="packSize">
              <Form.Label>
                <span className="headingText">Packaging size(Kg/Pack)</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="packSize"
                value={formData.packSize}
                onChange={findNumericAndDecimal}
                onBlur={localhandleOnblur}
                maxLength={10}
                className={`form-control ${packSizeError && "is-invalid"} ${
                  packSizeError === false && "is-valid"
                }`}
                required
              />
              <small className={`${packSizeError && "invalid-feedback"} `}>
                {!!packSizeError && packSizeError}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="ptype">
              <Form.Label>
                <span className="headingText">Packaging type</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="ptype"
                value={formData.ptype}
                onChange={handleInputChange}
                onBlur={localhandleOnblur}
                className={`form-control ${ptypeError && "is-invalid"} ${
                  ptypeError === false && "is-valid"
                }`}
                required
              >
                {packagingTypes.map((packagingType) => (
                  <option key={packagingType.value} value={packagingType.value}>
                    {packagingType.label}
                  </option>
                ))}
              </Form.Control>
              <small className={`${ptypeError && "invalid-feedback"} `}>
                {!!ptypeError && ptypeError}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Form.Group controlId="description">
              <Form.Label>
                <span className="headingText">Grade description</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={maxlengthofTextBox}
                onBlur={localhandleOnblur}
                className={`form-control ${descriptionError && "is-invalid"} ${
                  descriptionError === false && "is-valid"
                }`}
                required
              />
              <div
                className={`float-end pt-1 ${count > 500 && "text-danger"} `}
              >
                {count}/500 characters
              </div>
              <small className={`${descriptionError && "invalid-feedback"} `}>
                {!!descriptionError && descriptionError}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mt-5">
            <div className="float-end">
              <Button variant="success" onClick={handleNextButton}>
                Next
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default HerbalDetailForm;

import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import styles from "./style.module.css";
import attachImg from '../../../../../assets/dashboardImages/attach.svg';

const QualityForm = ({
  formData,
  handleInputChange,
  saveQualityDetails,
  previousStep,
  handleQualityInputChange,
  handleFileChange
}) => {
  const [inputs, setInputs] = useState([{ id: 0 , parameter: '', value: ''}]);
  const [reportLabel, setReportLabel] = useState('Upload');
  const [labError, setLabError] = useState(null);
  const [dateError, setDateError] = useState(null);

  const handleAddNew = () => {
    const newId = inputs.length;
    setInputs([...inputs, { id: newId, parameter: '', value: ''}]);
  };

  const handleRemove = (id) => {
    const updatedInputs = inputs.filter((input) => input.id !== id);
    setInputs(updatedInputs);
  };

  const handleSubmit = () => {
    if (!formData.labName) {
      setLabError("Please enter Lab name");
    } else{
      setLabError(null);
    }
    if (!formData.labDate) {
      setDateError("Please enter Lab date");
    } else{
      setDateError(null);
    }
    if(formData.labName && formData.labDate){
      saveQualityDetails();
    }
  };

  const handleParamValue = (id, inputName, value) => {
    const updatedInputs = inputs.map((input) => {
      if (input.id === id) {
        return { ...input, [inputName]: value };
      }
      return input;
    });
    setInputs(updatedInputs);
  
    const updatedQualityInputs = updatedInputs.map(({ id, ...rest }) => rest);
    console.log(updatedQualityInputs)
    handleQualityInputChange(updatedQualityInputs);
  };
  const renderFileName = (file) => {
    const fileName = file;
    const maxChars = 10; // Maximum number of characters to display
    let displayFileName = fileName;

    if (fileName.length > maxChars) {
      const startingChars = fileName.substring(0, maxChars);
      const extension = fileName.slice(fileName.lastIndexOf(".") + 1);
      displayFileName = `${startingChars}...${extension}`;
    }
    return displayFileName;
  }
  const handleReportFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileName = renderFileName(file.name);
      setReportLabel(fileName);
    }
  
    handleFileChange('labReport', file);
  };


const localhandleOnblur = (event) => {
    setErrorMessage(event.target.id);
  };

  const setErrorMessage = (id)=>{
      switch(id){
        case "labName":
          if (!formData.labName) {
            setLabError("Please enter Lab name");
          } else{
            setLabError(null);
          }
          break;
          case "labDate":
          if (!formData.labDate) {
            setDateError("Please enter Lab date");
          } else{
            setDateError(null);
          }
        break;
      }
  };

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <h3 className="formheadingText">Quality Details</h3>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="labName">
            <Form.Label>
              <span className="headingText">Lab name</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="labName"
              value={formData.labName}
              maxLength={255}
              onChange={handleInputChange}
              onBlur={localhandleOnblur}
              className={`form-control ${labError && 'is-invalid'} ${
                labError === false && 'is-valid'
              }`}
              required
            />
            <small className={`${labError && 'invalid-feedback'} `}>
              {!!labError && labError}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="labDate">
            <Form.Label>
              <span className="headingText">Lab testing date</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="labDate"
              value={formData.labDate}
              onChange={handleInputChange}
              onBlur={localhandleOnblur}
              className={`form-control ${dateError && 'is-invalid'} ${
                dateError === false && 'is-valid'
              }`}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
            <Form.Group controlId="labReport">
            <Form.Label>Lab Report</Form.Label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{borderRadius: '4px 0px 0px 4px'}}>
                        <img src={attachImg} style={{ padding: '10px 2px'}} />
                        </span>
                    </div>
                    <div className="custom-file form-control">
                        <label className="custom-file-label" style={{borderRadius: "0px 4px 4px 0px", width: "100%"}}>
                        <input type="file" accept="application/pdf" name='labReport' 
                        style={{ visibility:"hidden", width: "100%" }} 
                        className={`custom-file-input `}
                        onChange={handleReportFileChange} />
                        <span style={{position: "absolute", top: '', left: '1.5em'}}>{reportLabel}</span></label>
                    </div>
                </div>
            </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3 qualityParam">
        <Col lg={3}>Parameter</Col>
        <Col lg={3}>Value</Col>
      </Row>
      {inputs.map((input) => (
        <Row key={input.id} className="mt-1 qualityParam">
          <Col lg={3}>
            <Form.Group controlId="parameter">
              <Form.Control
                type="text"
                key={input.id}
                name="parameter"
                maxLength={255}
                placeholder="Input"
                onChange={(event)=>handleParamValue(input.id, 'parameter', event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="value">
              <Form.Control
                type="text"
                key={input.id}
                name="values"
                maxLength={255}
                placeholder="Input"
                onChange={(event)=>handleParamValue(input.id, 'value', event.target.value)}
              />
            </Form.Group>
          </Col>
          {inputs.length > 1 && (
            <Col lg={3}>
              <Button variant="danger" onClick={() => handleRemove(input.id)}>
                Remove
              </Button>

            </Col>
          )}
        </Row>
      ))}
      <Row>
        <Col lg={3}>
          <Button variant="primary" className="mt-2" onClick={handleAddNew}>
            Add New
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mt-5">
          <div className="float-end">
            <Button variant="secondary" onClick={previousStep}>
              Previous
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={handleSubmit}
            >
              Submit & Review
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default QualityForm;
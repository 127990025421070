import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProfileSaga } from "../saga";
import { sliceKey, reducer, actions } from "../slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "../selectors";
import { Col, Row, Form } from "react-bootstrap";
import editIcon from "../../../../../assets/icons/edit_icon.svg";
import Button from "../../../shared/button";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";
import Box from "@mui/material/Box";
import FileIcon from "../../../../../assets/icons/file.svg";
import { Link } from "react-router-dom";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import { useAuth } from "../../../../contexts/auth";
import { toast } from "react-toastify";

const containerStyle = {
  border: "2px dashed #E2E8F0",
  backgroundColor: "#F7FAFC",
  height: "98px",
  paddingLeft: "150px",
  paddingTop: "25px",
  borderRadius: "6px",
};
const fileContainerStyle = {
  border: "1px solid #EDF2F7",
  borderRadius: "6px",
  height: "48px",
  paddingTop: "12px",
  paddingLeft: "15px",
  paddingRight: "15px",
};

const BankDetail = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myProfileSaga });

  const {
    accountNumber,
    bankName,
    branchName,
    cancelledChequeUrl,
    ifsc,
    accountHolderName,
  } = useSelector(selectors.bankDetails);

  const [newBankName, setNewBankName] = useState("");

  const [newBranch, setNewBranch] = useState("");

  const [newAccountNumber, setNewAccountNumber] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [newIfsc, setNewIfsc] = useState("");

  const [fileToUpload, setFileToUplopad] = useState(null);

  const [newAccountHolder, setNewAccountHolder] = useState("");

  const dispatch = useDispatch();

  const auth = useAuth();

  useEffect(() => {
    setNewBankName(bankName);
    setNewBranch(branchName);
    setNewIfsc(ifsc);
    setNewAccountNumber(accountNumber);
    setNewAccountHolder(accountHolderName);
  }, [bankName, branchName, ifsc, accountNumber, accountHolderName]);

  const handleAccountHolderChange = (e) => {
    setNewAccountHolder(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files;
    setFileToUplopad(file);
  };
  const handleInputClick = () => {
    document.getElementById("input_file").click();
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleIfscChange = (e) => {
    setNewIfsc(e.target.value);
  };

  const handleAccountNumberChange = (e) => {
    setNewAccountNumber(e.target.value);
  };

  const handleBranchChange = (e) => {
    setNewBranch(e.target.value);
  };

  const handleBankNameChange = (e) => {
    setNewBankName(e.target.value);
  };

  const handleSubmit = () => {
    if (newBankName.trim() === "") {
      // setBankNameError('Bank Name is required.')
    }
    if (newBranch.trim() === "") {
      // setBranchNameError('Branch Name is required.')
    }
    if (newIfsc.trim() === "") {
      // setIfscCodeError('IFSC Code is required.')
    }
    if (newAccountNumber.trim() === "") {
      // setAccountNumberError('Account Number is required.')
    }
    if (
      newBankName !== "" ||
      newBranch !== "" ||
      newIfsc !== "" ||
      newAccountNumber !== "" ||
      newAccountHolder !== ""
    ) {
      const payload = {
        data: {
          id: auth.loggedInUser._id,
          bankName: newBankName,
          branchName: newBranch,
          ifsc: newIfsc,
          accountNumber: newAccountNumber,
          accountHolderName: newAccountHolder,
        },
        onSuccess: () => {
          setEditMode(false);
          const payload = {
            id: auth.loggedInUser._id,
          };
          dispatch(actions.fetchProfileDetails(payload));
          toast.success("Address has been updated successfully", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
      };
      if (fileToUpload) payload.data.cancelledChecque = fileToUpload;
      dispatch(actions.updateBankDetails(payload));
    }
  };

  if (!editMode) {
    return (
      <>
        <Col lg={10}>
          <h5>Bank details</h5>
        </Col>
        <div
          style={{
            width: "32px",
            cursor: "pointer",
            position: "absolute",
            right: "24px",
            top: "20px",
          }}
          onClick={handleEditMode}
        >
          <div
            style={{
              width: "24px",
            }}
          >
            <img src={editIcon} alt="edit" style={{ width: "100%" }} />
          </div>
        </div>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="bank_name">
            <Form.Label>
              <span className="headingText">Account holder</span>
            </Form.Label>
            <h6 style={{ textTransform: "capitalize" }}>{newAccountHolder}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="bank_name">
            <Form.Label>
              <span className="headingText">Bank</span>
            </Form.Label>
            <h6 style={{ textTransform: "capitalize" }}>{bankName}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="branch_name">
            <Form.Label>
              <span className="headingText">Branch</span>
            </Form.Label>

            <h6 style={{ textTransform: "capitalize" }}>{branchName}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="acc_number">
            <Form.Label>
              <span className="headingText">Account number</span>
            </Form.Label>

            <h6>{accountNumber}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-2">
          <Form.Group controlId="ifsc">
            <Form.Label>
              <span className="headingText">IFSC code</span>
            </Form.Label>
            <h6>{ifsc}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-3">
          <Form.Group controlId="c_cheque">
            <Form.Label>
              <span className="headingText">Cancelled cheque</span>
            </Form.Label>
            <div>
              {cancelledChequeUrl ? (
                <a
                  href={cancelledChequeUrl}
                  style={{ textDecoration: "none", color: "#38A169" }}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  cancel_cheque.pdf
                </a>
              ) : (
                <button
                  style={{
                    textDecoration: "none",
                    color: "#38A169",
                    background: "none",
                    border: "none",
                    outline: "none",
                  }}
                >
                  No cancelled checque found
                </button>
              )}
            </div>
          </Form.Group>
        </Col>
      </>
    );
  }
  return (
    <>
      <Col lg={10}>
        <h5>Bank details</h5>
      </Col>
      <div
        style={{
          width: "32px",
          cursor: "pointer",
          position: "absolute",
          right: "24px",
          top: "20px",
        }}
        onClick={handleEditMode}
      >
        <div
          style={{
            width: "16px",
          }}
        >
          <img src={closeIcon} alt="edit" style={{ width: "100%" }} />
        </div>
      </div>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="accountHolder">
          Account holder
        </label>
        <input
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
          type="text"
          name="accountHolder"
          value={newAccountHolder}
          onChange={handleAccountHolderChange}
        />
      </Col>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="bankName">
          Bank
        </label>
        <input
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
          type="text"
          name="bankName"
          value={newBankName}
          onChange={handleBankNameChange}
        />
      </Col>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="branch">
          Branch
        </label>
        <input
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
          type="text"
          name="branch"
          value={newBranch}
          onChange={handleBranchChange}
        />
      </Col>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="acc_number">
          Account
        </label>
        <input
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
          type="text"
          name="acc_number"
          value={newAccountNumber}
          onChange={handleAccountNumberChange}
        />
      </Col>
      <Col lg={3} className="mt-2">
        <label style={{ padding: "4px 0" }} htmlFor="ifsc">
          IFSC code
        </label>
        <input
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
          type="text"
          name="ifsc"
          value={newIfsc}
          onChange={handleIfscChange}
        />
      </Col>
      <div style={{ marginTop: "8px" }}>
        <Box style={containerStyle}>
          <input
            type="file"
            name=""
            id="input_file"
            style={{ display: "none" }}
            //ref={fileRef}
            onChange={handleFileChange}
            accept={".pdf"/*, .zip, .png, .jpeg*/}
          />
          <div style={{ display: "flex", gap: 2, marginLeft: "36px" }}>
            <div
              style={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Drag and drop files or
            </div>
            <div
              style={{
                color: "#38A169",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
              onClick={handleInputClick}
            >
              Browse
            </div>
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
            }}
          >
            Supported formats: JPEG, JPG, PNG, PDF
          </div>
        </Box>
        {fileToUpload && (
          <div style={{ marginTop: "8px" }}>
            <Box style={fileContainerStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 4 }}>
                  <div>
                    <img src={FileIcon} alt="file" />
                  </div>

                  <div
                    style={{
                      color: "#1A202C",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "2px",
                    }}
                  >
                    {fileToUpload[0].name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setFileToUplopad(null);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>
      <Col lg={6}></Col>
      <Col lg={6}>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            padding: "16px 0 8px 0",
          }}
        >
          <Button
            type="submit"
            text="Update"
            solid={true}
            buttonClick={handleSubmit}
          />
        </div>
      </Col>
    </>
  );
};

export default BankDetail;

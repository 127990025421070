import React, { useState } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./customTable.module.css";
import { formattedDate } from "../../../../utils/helper";


const UsersCustomTable = ({
  heading,
  filteredData,
  bids,
  onRowClick,
  type,
  dropDownValue,
}) => {
  
  return (
    <>
      <Table responsive>
        <thead style={{ backgroundColor: "#F7FAFC" }}>
          {type === "supplier"
            ? heading.map((headings, index) => (
                <tr key={index}>
                  <td className={styles.headT}>{headings.supplierName}</td>
                  <td className={styles.headT}>{headings.phoneNumber}</td>
                  
                  <td className={styles.headT}>{headings.regDate}</td>
                  {/* {dropDownValue === "Approved" ? (
                    <td className={styles.headT}>{headings.noOfAuctions}</td>
                  ) : (
                    <td className={styles.headT}>
                      {headings.participatedAuctions}
                    </td>
                  )} */}
                  <td className={styles.headT}>{headings.status}</td>
                </tr>
              ))
            : type === "buyers" &&
              heading.map((headings, index) => (
                <tr key={index}>
                  <td className={styles.headT}>{headings.buyerName}</td>
                  <td className={styles.headT}>{headings.phoneNumber}</td>                  
                  <td className={styles.headT}>{headings.regDate}</td>
                  {/* {dropDownValue === "Approved" ? (
                    <td className={styles.headT}>{headings.noOfAuctions}</td>
                  ) : (
                    <td className={styles.headT}>
                      {headings.participatedAuctions}
                    </td>
                  )} */}
                  <td className={styles.headT}>{headings.status}</td>
                </tr>
              ))}
        </thead>
        <tbody>
          {
             type === "supplier"
            ? filteredData.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    onRowClick(data.supplierID);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td className={styles.dataT}>{data.supplierName}</td>
                  <td className={styles.dataT}>{data.supplierNumber}</td>                  
                  <td className={styles.dataT}>
                    <span>{formattedDate(data.regDate)}</span>
                  </td>
                  {/* {dropDownValue === "Approved" ? (
                    <td className={styles.dataT}>
                      <span>{data.noOfAuctions}</span>
                    </td>
                  ) : (
                    <td className={styles.dataT}>
                      <span>{data.participatedAuctions}</span>
                    </td>
                  )} */}
                  <td className={styles.dataT}>
                    <span>{data.status}</span>
                  </td>
                </tr>
              ))
            : type === "buyers" &&
              filteredData.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    onRowClick(data.buyerID);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td className={styles.dataT}>
                    <span>{data.buyerName}</span>
                  </td>
                  <td className={styles.dataT}>
                    <span>{data.buyerNumber}</span>
                  </td>
                  <td className={styles.dataT}>
                    <span>{formattedDate(data.regDate)}</span>
                  </td>
                  {/* {dropDownValue === "Approved" ? (
                    <td className={styles.dataT}>
                      <span>{data.noOfAuctions}</span>
                    </td>
                  ) : (
                    <td className={styles.dataT}>
                      <span>{data.participatedAuctions}</span>
                    </td>
                  )} */}
                  <td className={styles.dataT}>
                    <span>{data.status}</span>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </>
  );
};

export default UsersCustomTable;

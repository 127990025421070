import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Badge, Dropdown } from "react-bootstrap";
import styles from "./Auctions.module.css";
import Heading from "../../../components/shared/Heading";
import SearchIcon from "../../../../assets/dashboardImages/searchBlack.svg";
import CustomTable from "../../../components/dashboardApp/customTable";
import TableFooter from "../../../components/dashboardApp/tableFooter";
import { useNavigate } from "react-router-dom";
import AdminCustomTable from "../../../components/dashboardApp/adminCustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions, initialState } from "./slice";
import { adminAuctionListingSaga } from "./saga";
import * as selectors from "./selectors";
import * as utilFunctions from "../../../utils/helper";
import AuctionCustomTable from "../../../components/dashboardApp/adminCustomTable/auctionTable";

const Auctions = () => {
  const navigate = useNavigate();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: adminAuctionListingSaga });
  const dispatch = useDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [fromPage, setFromPage] = useState(1);
  const [dropDownValue, setDropDownValue] = useState("review");
  const [dropDownText, setDropDownText] = useState("In Review");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const payload = {
      status: dropDownValue,
      name: searchQuery,
      page: currentPage - 1,
      limit: itemsPerPage,
    };
    dispatch(actions.fetchAdminAuctions(payload));
  }, [dispatch, dropDownValue, searchQuery, currentPage, itemsPerPage]);

  const auctionList = useSelector(selectors.selectauctionLlist) || initialState;

  const allAuctionList = [];
  // const liveAuctionHeads = [
  //   {
  //     auctionID: "Auction ID",
  //     supplierID: "Supplier ID",
  //     supplierName: "Supplier Name",
  //     herbName: "Herb Name",
  //     launchDate: "Launch Date",
  //     createdDate: "Created Date",
  //     noOfbids: "No of Bids",
  //     quantity: "Quantity",
  //     askedPricePerUnit: "Ask price per unit",
  //     highbid: "H1 Bid/Kg (₹)",
  //     status: "Status",
  //   },
  // ];
  // const inReviewHeading = [
  //   {
  //     auctionID: "Auction ID",
  //     supplierID: "Supplier ID",
  //     supplierName: "Supplier Name",
  //     herbName: "Herb Name",
  //     createdDate: "Created Date",
  //     quantity: "Quantity",
  //     askedPricePerUnit: "Ask price per unit",
  //     status: "Status",
  //   },
  // ];

  const liveAuctionHeading = [
    {
      auctionID: "Auction ID",
      supplierID: "Supplier ID",
      supplierName: "Supplier Name",
      herbName: "Herb Name",
      launchDate: "Launch Date",
      createdDate: "Created Date",
      noOfbids: "No of Bids",
      quantity: "Quantity",
      askedPricePerUnit: "Ask price per unit",
      highbid: "H1 Bid/Kg (₹)",
      status: "Status",
    },
  ];

  // const auctionStatus = ["Live", "In Review", "Awarded", "Pending Award"];
  if (auctionList && auctionList.auctions) {
    const dbAuctions = auctionList.auctions;
    for (let i = 0; i < dbAuctions.length; i++) {
      allAuctionList.push({
        id: dbAuctions[i]['_id'],
        auctionID:
          dbAuctions[i]["auctionIdForDisplay"] ?
          utilFunctions.formatedAuctionId(dbAuctions[i]["auctionIdForDisplay"]) : "___", // "AU00" + i,
        supplierID: "HC00" + i,
        supplierName:
          dbAuctions[i]["createdBy"] &&
          dbAuctions[i]["createdBy"]["companyName"]
            ? dbAuctions[i]["createdBy"]["companyName"]
            : "--",
        herbName: dbAuctions[i]["herbName"], // "AU00" + i,"Rosemary",
        launchDate: "30 May 2023",
        createdDate: dbAuctions[i]["createdAt"], // "20 May 2022",
        noOfbids:
          dbAuctions[i]["bids"] && dbAuctions[i]["bids"].length
            ? dbAuctions[i]["bids"].length
            : "--",
        quantity: dbAuctions[i]["totalQuantity"],
        askedPricePerUnit: dbAuctions[i]["askedPricePerUnit"],
        highbid: "11",
        supplierEmail: "check@gmail.com",
        supplierNumber: "9027202890",
        status: dbAuctions[i]["status"], //auctionStatus[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      });
    }
  }

  const totalCount = auctionList && auctionList.totalRecords;
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (page) => {
    // console.log({ page: page });
    setCurrentPage(page);
  };

  const handleAuctionClick = (auctionId) => {
    navigate(auctionId);
  };

  return (
    <>
      <section>
        <Container fluid>
          <Row>
            <Col lg={8} className={styles.marginBottom36}>
              <h2>Auctions</h2>
            </Col>
            {/* priority is slow of customize view */}
            <Col lg={1} className={styles.marginBottom36}>
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {dropDownText}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => {
                      setDropDownValue("live");
                      setDropDownText("Live");
                    }}
                  >
                    Live
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setDropDownValue("review");
                      setDropDownText("In Review");
                    }}
                  >
                    In Review
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setDropDownValue("awarded");
                      setDropDownText("Awarded");
                    }}
                  >
                    Awarded
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setDropDownValue("bid_closed");
                      setDropDownText("Pending");
                    }}
                  >
                    Pending
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setDropDownValue("rejected");
                      setDropDownText("Rejected");
                    }}
                  >
                    Rejected
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setDropDownValue("cancelled");
                      setDropDownText("Cancelled");
                    }}
                  >
                    Cancelled
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col lg={3} className={styles.marginBottom36}>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span
                    className={["input-group-text", styles.basic_addon].join(
                      " "
                    )}
                    id="basic-addon1"
                  >
                    <img src={SearchIcon} alt="" />
                  </span>
                </div>
                <input
                  type="text"
                  id={styles.search}
                  className="form-control"
                  placeholder="Herb Name"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
              {/* <input type="text"  value={searchQuery} onChange={handleSearchInputChange} /> */}
            </Col>
          </Row>
          <Row>
            <Col lg={12} className={styles.cardTable}>
              <AuctionCustomTable
                heading={liveAuctionHeading}
                filteredData={allAuctionList}
                bids={true}
                onRowClick={handleAuctionClick}
                dropDownValue={dropDownValue}
                type={"auctions"}
              />
            </Col>
          </Row>

          {pageCount > 1 ? (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              currentPage={currentPage}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              totalCount={totalCount}
              fromCount={fromPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </section>
    </>
  );
};

export default Auctions;

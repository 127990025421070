import React, { useRef, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import FileIcon from "../../../../../assets/icons/file.svg";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";

export default function LegalDetails(props) {
  const [aadharNumber, setAadharNumber] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [aadharToUpload, setAadharToUplopad] = useState(null);
  const [gstToUpload, setGstToUplopad] = useState(null);
  const [panToUpload, setPanToUplopad] = useState(null);
  const [aadharNumberError, setAadharNumberError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [panNumberError, setPanNumberError] = useState("");
  const [aadharToUploadError, setAadharToUplopadError] = useState(null);
  const [gstToUploadError, setGstToUplopadError] = useState(null);
  const [panToUploadError, setPanToUplopadError] = useState(null);

  const handleAadharChange = (event) => {
    setAadharNumber(event.target.value);
    setAadharNumberError("");
  };
  const handleGstChange = (event) => {
    setGstNumber(event.target.value);
    setGstNumberError("");
  };
  const handlePanChange = (event) => {
    setPanNumber(event.target.value);
    setPanNumberError("");
  };

  const panInputStyles = {
    height: "16px",
    width: "584px",
    borderRadius: "6px",
  };
  const mobilepanInputStyles = {
    height: "16px",
    width: "100%",
    borderRadius: "6px",
  };
  const containerStyle = {
    border: "2px dashed #E2E8F0",
    backgroundColor: "#F7FAFC",
    height: "98px",
    paddingLeft: "150px",
    paddingTop: "25px",
    borderRadius: "6px",
  };
  const mobilecontainerStyle = {
    border: "2px dashed #E2E8F0",
    backgroundColor: "#F7FAFC",
    height: "98px",
    paddingLeft: "50px",
    paddingTop: "25px",
    borderRadius: "6px",
  };
  const fileContainerStyle = {
    border: "1px solid #EDF2F7",
    borderRadius: "6px",
    height: "48px",
    paddingTop: "12px",
    paddingLeft: "15px",
    paddingRight: "15px",
  };
  const backButtonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "#FFFFFF !important",
      border: "1px solid #E2E8F0 !important",
      boxShadow: "none !important",
    },
  };

  const verifyButtonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "green !important",
    },
  };
  const mbbackButtonStyles = {
    root: {
      width: "150px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "#FFFFFF !important",
      border: "1px solid #E2E8F0 !important",
      boxShadow: "none !important",
    },
  };

  const mbverifyButtonStyles = {
    root: {
      width: "150px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "green !important",
    },
  };
  const checkAadharNumber = (aadharNumber) => {
    const pattern = /^\d{12}$/;
    return pattern.test(aadharNumber);
  };
  const checkGSTNumber = (gstNumber) => {
    const pattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/;
    return pattern.test(gstNumber);
  };

  const checkPanNumber = (panNumber) => {
    const pattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return pattern.test(panNumber);
  };

  const handleSubmit = () => {
    if (aadharNumber.trim() === "") {
      setAadharNumberError("Aadhar number is required.");
      // return;
    } else {
      if (checkAadharNumber(aadharNumber.trim())) {
        setAadharNumberError("");
      } else {
        setAadharNumberError(
          "Invalid Aadhar number. Please enter a 12-digit number."
        );
        return;
      }
    }
    if (gstNumber.trim() === "") {
      //setGstNumberError("GST number is required.");
      // return;
      /*Commenting the above changes as per requirement raised by Sahil on 05/01/2024 
      to accomodete onboarding of Farmer without gst as a mandatory field*/
    } else {
      if (checkGSTNumber(gstNumber.trim())) {
        setGstNumberError("");
      } else {
        setGstNumberError(
          "Invalid GST number. Please enter a valid GST number."
        );
        return;
      }
    }
    if (panNumber.trim() === "") {
      setPanNumberError("PAN number is required.");
      return;
    } else {
      if (checkPanNumber(panNumber.trim())) {
        setPanNumberError("");
      } else {
        setPanNumberError(
          "Invalid PAN number. Please enter a valid PAN number."
        );
        return;
      }
    }
    if (
      aadharToUpload === null &&
      gstToUpload === null &&
      panToUpload === null
    ) {
      setAadharToUplopadError(
        "Any one of the abouve Certificates is required."
      );
    }
    if (
      aadharNumber !== "" ||
      gstNumber !== "" ||
      panNumber !== "" ||
      aadharToUpload !== null ||
      gstToUpload !== null ||
      panToUpload !== null
    ) {
      props.nextButtonClicked(
        aadharNumber,
        gstNumber,
        panNumber,
        aadharToUpload,
        gstToUpload,
        panToUpload
      );
    }
  };

  const handleInputClick = (id) => {
    document.getElementById(id).click();
  };

  const widthCheck = window.innerWidth <= 768;
  const CustomButton = withStyles(widthCheck?mbbackButtonStyles:backButtonStyles)(({ classes, ...props }) => (
    <Button className={classes.root} {...props} />
  ));

  const CustomVerifyButton = withStyles(widthCheck? mbverifyButtonStyles:verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );

  return (
    <>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
        }}
      >
        Legal documents
      </div>
      <div
        style={{
          color: "#718096",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          marginTop: "8px",
        }}
      >
        Enter legal documents
      </div>
      <div
        style={{
          marginTop: "32px",
        }}
      >
        <div
          style={{
            color: "#2D3748",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Adhaar number
        </div>
        <div style={{ marginTop: "8px" }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            placeholder="Ex. 6245 XXXX XXXX"
            inputProps={{
              style: widthCheck? mobilepanInputStyles:panInputStyles,
              maxLength: 12
            }}
            value={aadharNumber}
            onChange={handleAadharChange}
          />
          {aadharNumberError && (
            <FormHelperText error>{aadharNumberError}</FormHelperText>
          )}
        </div>
      </div>
      <div style={{ display: "flex", gap: 2, margin: "16px auto 8px auto" }}>
        <div
          style={{
            color: "#2D3748",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Upload Your Aadhar
        </div>
        <div
          style={{
            color: "#718096",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          (if any)
        </div>
      </div>
      <Box style={ widthCheck? mobilecontainerStyle : containerStyle }>
        <input
          type="file"
          name=""
          id="input_file_aadhar"
          style={{ display: "none" }}
          //ref={fileRef}
          onChange={(event) => {
            setAadharToUplopad(event.target.files);
          }}
          accept={".pdf" /*, .zip, .png, .jpeg*/}
        />
        <div
          style={{
            display: "flex",
            gap: 2,
            marginLeft: "36px",
          }}
        >
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Drag and drop files or
          </div>
          <div
            style={{
              color: "#38A169",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleInputClick("input_file_aadhar")}
          >
            Browse
          </div>
        </div>
        <div
          style={{
            color: "#000000",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "8px",
          }}
        >
          Supported formats: JPEG, JPG, PNG, PDF
        </div>
      </Box>
      {aadharToUpload && (
        <div style={{ marginTop: "8px" }}>
          <Box style={fileContainerStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: 4 }}>
                <div>
                  <img src={FileIcon} alt="file" />
                </div>

                <div
                  style={{
                    color: "#1A202C",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginTop: "2px",
                  }}
                >
                  {aadharToUpload[0].name}
                </div>
              </div>
              <div
                onClick={() => {
                  setAadharToUplopad(null);
                  setAadharToUplopadError(null);
                }}
              >
                <img
                  src={deleteIcon}
                  alt="deleteIcon"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </Box>
        </div>
      )}
      <div style={{ margin: "32px auto 8px auto" }}>
        <div
          style={{
            color: "#2D3748",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          GST number
        </div>
        <div style={{ marginTop: "8px" }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            placeholder="Ex. ABCDXXXXXXXXXXX"
            inputProps={{
              style: widthCheck? mobilepanInputStyles:panInputStyles,
              maxLength: 15,
            }}
            value={gstNumber}
            onChange={handleGstChange}
          />
          {gstNumberError && (
            <FormHelperText error>{gstNumberError}</FormHelperText>
          )}
        </div>
      </div>
      <div style={{ display: "flex", gap: 2, margin: "20px auto 8px auto" }}>
        <div
          style={{
            color: "#2D3748",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Upload GST certificate
        </div>
        <div
          style={{
            color: "#718096",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          (if any)
        </div>
      </div>
      <Box style={widthCheck? mobilecontainerStyle : containerStyle}>
        <input
          type="file"
          name=""
          id="input_file_gst"
          style={{ display: "none" }}
          //ref={fileRef}
          onChange={(event) => {
            setGstToUplopad(event.target.files);
            setGstToUplopadError(null);
          }}
          accept={".pdf" /*, .zip, .png, .jpeg*/}
        />
        <div
          style={{
            display: "flex",
            gap: 2,
            marginLeft: "36px",
          }}
        >
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Drag and drop files or
          </div>
          <div
            style={{
              color: "#38A169",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleInputClick("input_file_gst")}
          >
            Browse
          </div>
        </div>
        <div
          style={{
            color: "#000000",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "8px",
          }}
        >
          Supported formats: JPEG, JPG, PNG, PDF
        </div>
      </Box>
      {gstToUpload && (
        <div style={{ marginTop: "8px" }}>
          <Box style={fileContainerStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: 4 }}>
                <div>
                  <img src={FileIcon} alt="file" />
                </div>

                <div
                  style={{
                    color: "#1A202C",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginTop: "2px",
                  }}
                >
                  {gstToUpload[0].name}
                </div>
              </div>
              <div
                onClick={() => {
                  setGstToUplopad(null);
                }}
              >
                <img
                  src={deleteIcon}
                  alt="deleteIcon"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </Box>
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            color: "#2D3748",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          PAN number
        </div>
        <div style={{ marginTop: "8px" }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            placeholder="Ex. ABCDXXXXXX"
            inputProps={{
              style: widthCheck? mobilepanInputStyles:panInputStyles,
              maxLength: 10,
            }}
            value={panNumber}
            onChange={handlePanChange}
          />

          {panNumberError && (
            <FormHelperText error>{panNumberError}</FormHelperText>
          )}
        </div>
      </div>
      <div style={{ display: "flex", gap: 2, marginTop: "20px" }}>
        <div
          style={{
            color: "#2D3748",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Upload PAN
        </div>
        <div
          style={{
            color: "#718096",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          (if any)
        </div>
      </div>
      <div style={{ marginTop: "8px" }}>
        <Box style={widthCheck? mobilecontainerStyle : containerStyle}>
          <input
            type="file"
            name=""
            id="input_file_pan"
            style={{ display: "none" }}
            //ref={fileRef}
            onChange={(event) => {
              setPanToUplopad(event.target.files);
              setPanToUplopadError(null);
            }}
            accept={".pdf" /*, .zip, .png, .jpeg*/}
          />
          <div
            style={{
              display: "flex",
              gap: 2,
              marginLeft: "36px",
            }}
          >
            <div
              style={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Drag and drop files or
            </div>
            <div
              style={{
                color: "#38A169",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleInputClick("input_file_pan")}
            >
              Browse
            </div>
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
            }}
          >
            Supported formats: JPEG, JPG, PNG, PDF
          </div>
        </Box>
        {panToUpload && (
          <div style={{ marginTop: "8px" }}>
            <Box style={fileContainerStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 4 }}>
                  <div>
                    <img src={FileIcon} alt="file" />
                  </div>

                  <div
                    style={{
                      color: "#1A202C",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "2px",
                    }}
                  >
                    {panToUpload[0].name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setPanToUplopad(null);
                    setPanToUplopadError(null);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>
      <div style={{ display: "flex", gap: 40, marginTop: "160px" }}>
        <div>
          <CustomButton onClick={props.backButtonClicked} variant="contained">
            <div
              style={{
                color: "#1A202C",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Back
            </div>
          </CustomButton>
        </div>
        <div>
          <CustomVerifyButton onClick={handleSubmit}>
            <div
              style={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Next
            </div>
          </CustomVerifyButton>
        </div>
      </div>
    </>
  );
}

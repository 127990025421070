import React, { useState, useRef } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteIcon from "../../../../../assets/icons/deleteIconred.svg";
import styles from "./style.module.css";
import { useDropzone } from "react-dropzone";

const ImageForm = ({
  formData,
  handleInputChange,
  saveImages,
  previousStep,
}) => {
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);
  const fileInputRef = useRef(null);

  const handleDrop = (acceptedFiles) => {
    const updatedFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };

  const handleRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleNext = () => {
    if (files.length === 0) {
      setFileError("Please select an image");
    } else {
      setFileError(null);
      saveImages(files);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "image/*",
  });

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <Row>
        <Col lg={12} className="mt-5">
          <h3 className="formheadingText mb-3">Upload Images</h3>
          <div
            {...getRootProps()}
            className={styles.dropzone}
            onClick={handleClick}
          >
            <input
              {...getInputProps()}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <p>
              Drag and drop files or{" "}
              <span className="text-success">Browse</span>
            </p>
          </div>
          <div>
            <small className={`${fileError && 'invalid-feedback'}`} style={{ display: 'unset' }}>
              {!!fileError && fileError}
            </small>
          </div>
          <div className="row">
            <div className={[styles.previewContainer].join(" ")}>
              {files.map((file, index) => (
                <div
                  className={[styles.preview, "col-md-2"].join(" ")}
                  style={{ textAlign: "center" }}
                  key={index}
                >
                  <Image
                    src={file.preview}
                    thumbnail={true}
                    style={{ height: "8rem" }}
                  />
                  <p style={{ color: "#4A5568" }}>
                    {file.name}
                    <Link
                      to={"#"}
                      style={{ textDecoration: "none" }}
                      onClick={() => handleRemove(index)}
                    >
                      <img src={deleteIcon} style={{ width: "20px" }} />
                    </Link>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mt-5">
          <div className="float-end">
            <Button variant="secondary" onClick={previousStep}>
              Previous
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImageForm;

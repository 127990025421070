import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as selectors from "../selectors";
import { Col, Row, Form } from "react-bootstrap";
import attachImg from "../../../../../assets/dashboardImages/attach.svg";

const profileData = {
  aadharNumber: "124355983465",
  gst: "24AAACC1206D1ZM",
  pan: "ABCTY1234D",
};

const LegalDoc = () => {
  // const [certLabel, setCertLabel] = useState("legal_cert.pdf");

  const { aadharNumber, gst, pan, aadharUrl, gstUrl, panUrl } = useSelector(
    selectors.legalDetails
  );

  // const handleCertFileChange = (event) => {
  //   const file = event.target.files[0];

  //   if (event.target.files[0].name != null) {
  //     setCertLabel(event.target.files[0].name);
  //   }
  // };
  return (
    <>
      <Col lg={12}>
        <h5>Legal documents</h5>
      </Col>
      <Col lg={4} className="mt-2">
        <Form.Group controlId="aadhar_number">
          <Form.Label>
            <span className="headingText">Aadhaar number</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="aadhar_number"
            defaultValue={aadharNumber}
          />
        </Form.Group>
        <div style={{ marginTop: "12px", textAlign: "center" }}>
          {aadharUrl ? (
            <a
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
              href={aadharUrl}
              target="_blank"
              rel="noreferrer"
            >
              aadhar.pdf
            </a>
          ) : (
            <div
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
            >
              aadhar file not found
            </div>
          )}
        </div>
      </Col>
      <Col lg={4} className="mt-2">
        <Form.Group controlId="gst">
          <Form.Label>
            <span className="headingText">GST number</span>
          </Form.Label>
          <Form.Control type="text" name="gst" defaultValue={gst} />
        </Form.Group>
        <div style={{ marginTop: "12px", textAlign: "center" }}>
          {gstUrl ? (
            <a
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
              href={gstUrl}
              target="_blank"
              rel="noreferrer"
            >
              gst.pdf
            </a>
          ) : (
            <div
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
            >
              gst file not found
            </div>
          )}
        </div>
      </Col>
      <Col lg={4} className="mt-2">
        <Form.Group controlId="pan">
          <Form.Label>
            <span className="headingText">PAN number</span>
          </Form.Label>
          <Form.Control type="text" name="pan" defaultValue={pan} />
        </Form.Group>
        <div style={{ marginTop: "12px", textAlign: "center" }}>
          {panUrl ? (
            <a
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
              href={panUrl}
              target="_blank"
              rel="noreferrer"
            >
              pan.pdf
            </a>
          ) : (
            <div
              style={{
                textDecoration: "none",
                color: "#38A169",
                background: "none",
                border: "none",
                outline: "none",
              }}
            >
              pan file not found
            </div>
          )}
        </div>
      </Col>
      {/* <Col lg={3} className="mt-2">
        <Form.Group controlId="sdo">
          <Form.Label>
            <span className="headingText">Registration certificate</span>
          </Form.Label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text"
                style={{ borderRadius: "4px 0px 0px 4px" }}
              >
                <img src={attachImg} style={{ padding: "10px 2px" }} alt="" />
              </span>
            </div>
            <div className="custom-file form-control">
              <label
                className="custom-file-label"
                style={{ borderRadius: "0px 4px 4px 0px" }}
              >
                <input
                  type="file"
                  style={{ visibility: "hidden", width: "100%" }}
                  className="custom-file-input"
                  onChange={handleCertFileChange}
                />
                <span style={{ position: "absolute", top: "", left: "1.5em" }}>
                  {certLabel}
                </span>
              </label>
            </div>
          </div>
        </Form.Group>
      </Col> */}
    </>
  );
};

export default LegalDoc;

import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import image1 from "../../../../assets/homeImages/auctionView1.svg";
import image2 from "../../../../assets/homeImages/auctionView2.svg";
import image3 from "../../../../assets/homeImages/auctionView3.svg";
import image4 from "../../../../assets/homeImages/auctionView4.svg";
import previousIcon from "../../../../assets/icons/backward.svg";
import nextIcon from "../../../../assets/icons/forward.svg";
import hederaLogo from "../../../../assets/icons/Hedera logo.svg";
import Button from "../../shared/button";
import { useAuth } from "../../../contexts/auth";
import PlaceBid from "../../dashboardApp/PlaceBidInputButton";
import { myHighestBid } from "../../../utils/helper";
import { useNavigate } from "react-router";

const noimages = [
  { url: image1, id: 0 },
  { url: image2, id: 1 },
  { url: image3, id: 2 },
  { url: image4, id: 3 },
];

const btnStyle = {
  fontWeight: "600",
};

export default function QuickAuctionView({
  setOpenTraceability,
  setOpenQuality,
  auction,
  fetchQuickViewAuctionDetail,
}) {
  let { auctionDetail, highestBidAmount, myBids, userHighestBidAmount } =
    auction;
  let images = auctionDetail && auctionDetail.images;
  const [image, setImage] = useState(0);
  console.log({'auctionDetail':auctionDetail});
  useEffect(() => {
    return () => {
      images = [];
    }
  }, [])

  const auth = useAuth();
  const navigate = useNavigate();

  const handlePrev = () => {
    if (image === 0) return;
    const imageIndex = image - 1;
    setImage(imageIndex);
  };

  const handleNext = () => {
    if (image === images.length - 1) return;
    const imageIndex = image + 1;
    setImage(imageIndex);
  };

  const handleSelectImage = (index) => {
    console.log(index);
    setImage(index);
  };

  const myHigBid =
    auth.loggedInUser && auction.bids && auction.bids.length
      ? myHighestBid(auction.bids, auth.loggedInUser._id)
      : null;

  return (
    <section className={styles.auctionView}>
      <section className={styles.imageViewer}>
        <div className={styles.imageContainer}>
          {images && images.length > 0 ? (
            <>
              <img src={images[image].imageUrl} alt={`herb name`} />
            </>
          ) : (
            <img src={noimages[image].image} alt={`herb name`} />
          )}
        </div>
        <div className={styles.imageSelector}>
          <img
            className={styles.iconLeft}
            onClick={handlePrev}
            src={previousIcon}
            alt="Previous Icon"
          />
          <div className={styles.thumbnailList}>
            {images &&
              images.length > 0 &&
              images.map((image, index) => (
                <div key={index} className={styles.imageContainerSmall}>
                  <img
                    onClick={() => handleSelectImage(index)}
                    src={image.imageUrl}
                    alt="herb name"
                  />
                </div>
              ))}
          </div>
          <img
            className={styles.iconRight}
            onClick={handleNext}
            src={nextIcon}
            alt="Next Icon"
          />
        </div>
      </section>
      {auction && auction.auctionDetail && (
        <section className={styles.auctionDetails}>
          <span className={styles.liveBadge}>Live</span>
          <section className={styles.title}>
            <h2>{auction.auctionDetail.herbName}</h2>
            <p>{auction.auctionDetail.herbVariety}</p>
          </section>
          <p className={styles.quantity}>
            Total Quantity{" "}
            <span>
              {auction.auctionDetail.totalQuantity}/
              {auction.auctionDetail.quantityUnit}
            </span>
          </p>
          <section className={styles.pricing}>
            <div className={styles.left}>
              <p className={styles.light}>Ask price</p>
              <p className={styles.bold}>
                ₹{auction.auctionDetail.askedPricePerUnit}/
                {auction.auctionDetail.quantityUnit}
              </p>
            </div>
            <div className={styles.right}>
              <p className={styles.light}>Highest bid</p>
              <p className={styles.bold}>
                ₹{auction.highestBidAmount}/{auction.auctionDetail.quantityUnit}
              </p>
            </div>
          </section>
          {!auth.loggedInUser && (
            <>
              <Button
                solid={true}
                text="Login to bid"
                buttonClick={() => {
                  // console.log("button clicked");
                  navigate("/login?l=t");
                }}
                buttonStyle={btnStyle}
              />
            </>
          )}

          {auth.loggedInUser &&
            auth.loggedInUser.role === "buyer" &&
            auctionDetail.status === "live" && (
              <>
                <PlaceBid
                  placeholderText="Enter price"
                  asked={auctionDetail.askedPricePerUnit}
                  highbid={highestBidAmount}
                  auctionID={auctionDetail._id}
                  bidIncrementAmount={auctionDetail.bidIncrementAmount}
                  myHighestBid={myHigBid ? myHigBid.amount : 0}
                  buttonText="Bid"
                  loadAuctionDetail={fetchQuickViewAuctionDetail}
                />
              </>
            )}
          <p className={styles.bidIncrement}>
            {/* Bid increment{" "} */}
            <span>
              {/* ₹{auction.auctionDetail.bidIncrementAmount}/
              {auction.auctionDetail.quantityUnit} */}
            </span>
          </p>
          <section className={styles.traceability}>
            <div
              className={styles.traceabilityLeft}
              onClick={() => setOpenTraceability(true)}
            >
              <img src={hederaLogo} alt="hedera logo" />
              <span>Traceability</span>
            </div>
            <div
              className={styles.traceabilityRight}
              onClick={() => setOpenQuality(true)}
            >
              <span>Quality details</span>
            </div>
          </section>
        </section>
      )}
    </section>
  );
}

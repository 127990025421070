import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  profileDetails:{},
};

const profileDetails = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    fetchProfileDetails(state, action) {
      state.loading = true;
    },
    fetchProfileDetailsSuccess(state, action) {      
      state.profileDetails = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    fetchProfileDetailsFailure(state, action) {
      state.loading = false;
    },
    downloadAddressProof(state, action) {
      state.loading = true;
    },
    downloadAddressProofSuccess (state, action) {
      state.loading = false;
    },
    downloadAddressProofFailure (state, action) {
      state.loading = false;
    },
    updateAddressDetails (state, action) {
      state.loading = true;
    },
    updateAddressDetailsSuccess (state, action) {
      state.loading = false;
    },
    updateAddressDetailsFailure (state, action) {
      state.loading = false;
    },
    updateBankDetails (state, action) {
      state.loading = true;
    },
    updateBankDetailsSuccess (state, action) {
      state.loading = false;
    },
    updateBankDetailsFailure (state, action) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = profileDetails;

import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import closeIcon from "../../../../assets/icons/closeIcon.svg";

export default function Modal({
  showModal,
  children,
  closeModal,
  modalStyle,
  closeButton,
  enableBackground,
}) {
  const modalRef = useRef(null);

  useEffect(() => {
    if (showModal && !enableBackground) {
      document.body.style.overflow = "hidden";
    }

    return () => (document.body.style.overflow = "unset");
  }, [showModal, enableBackground]);

  return (
    <div
      ref={modalRef}
      className={styles.backdrop}
      style={enableBackground ? { backgroundColor: "rgba(0, 0, 0, .1)" } : {}}
      onClick={(e) => {
        e.stopPropagation();
        if (e.target.isEqualNode(modalRef.current)) {
          closeModal();
        }
      }}
    >
      <div style={modalStyle} className={styles.modal+' '}>
        {closeButton ? (
          <div className={styles.close} onClick={closeModal}>
            <img src={closeIcon} alt="close icon" />
          </div>
        ) : null}

        {children}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import styles from "./styles.module.css";
import image1 from "../../../../assets/homeImages/auctionView1.svg";
import image2 from "../../../../assets/homeImages/auctionView2.svg";
import image3 from "../../../../assets/homeImages/auctionView3.svg";
import image4 from "../../../../assets/homeImages/auctionView4.svg";
import previousIcon from "../../../../assets/icons/backward.svg";
import nextIcon from "../../../../assets/icons/forward.svg";
import hederaLogo from "../../../../assets/icons/Hedera logo.svg";
import Button from "../../shared/button";
import Modal from "../modal";
import TraceabilityDetails from "../traceability";
import QualityDetails from "../qualityDetails";
import { useAuth } from "../../../contexts/auth";
import PlaceBid from "../../dashboardApp/PlaceBidInputButton";
import { useNavigate } from "react-router";

const noimages = [{ url: image1, id: 0 }];
const btnStyle = {
  fontWeight: "600",
};

const modalStyle = {
  width: "600px",
  height: "100%",
  borderRadius: "0",
  left: "100%",
  top: "50%",
  transform: "translate(-100%, -50%)",
  padding: "0",
};
const modalCardStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "0",
  left: "100%",
  top: "50%",
  transform: "translate(-100%, -50%)",
  padding: "0",

};

export default function AuctionView(props) {
  const auth = useAuth();
  const navigate = useNavigate();
  let { auctionDetail, highestBidAmount, myBids, userHighestBidAmount } =
    props.auction;
  const images = auctionDetail.images;

  const [image, setImage] = useState(0);

  const [openTraceability, setOpenTraceability] = useState(false);
  const [openQuality, setOpenQuality] = useState(false);

  const handleCloseTraceabilty = () => {
    setOpenTraceability(false);
  };
  const handleCloseQuality = () => {
    setOpenQuality(false);
  };

  const handlePrev = () => {
    if (image === 0) return;
    const imageIndex = image - 1;
    setImage(imageIndex);
  };

  const handleNext = () => {
    if (image === images.length - 1) return;
    const imageIndex = image + 1;
    setImage(imageIndex);
  };

  const handleSelectImage = (index) => {
    setImage(index);
  };
  const widthCheck = window.innerWidth <= 768;

  return (
    <>
      <section className={styles.auctionView}>
        <section className={styles.imageViewer}>
          <div className={styles.imageContainer}>
            {images.length > 0 ? (
              <>
                <img src={images[image].imageUrl} alt={`herb name`} />
              </>
            ) : (
              <>
                <img src={noimages[image].image} alt={`herb name`} />
              </>
            )}
          </div>
          <div className={styles.imageSelector}>
            <img
              className={styles.iconLeft}
              onClick={handlePrev}
              src={previousIcon}
              alt="Previous Icon"
            />
            <div className={styles.thumbnailList}>
              {images.length > 0 &&
                images.map((image, index) => (
                  <div className={styles.imageContainerSmall}>
                    <img
                      onClick={() => handleSelectImage(index)}
                      key={index}
                      src={image.imageUrl}
                      alt="herb name"
                    />
                  </div>
                ))}
            </div>
            <img
              className={styles.iconRight}
              onClick={handleNext}
              src={nextIcon}
              alt="Next Icon"
            />
          </div>
        </section>
        <section className={styles.auctionDetails}>
          <span className={styles.liveBadge}>{auctionDetail.status}</span>
          <section className={styles.title}>
            <h2>{auctionDetail.herbName}</h2>
            <p>{auctionDetail.herbVariety}</p>
          </section>
          <p className={styles.quantity}>
            Total Quantity{" "}
            <span>
              {auctionDetail.totalQuantity} {auctionDetail.quantityUnit}
            </span>
          </p>
          <section className={styles.pricing}>
            <div className={styles.left}>
              <p className={styles.light}>Ask price</p>
              <p className={styles.bold}>
                ₹{auctionDetail.askedPricePerUnit}/{auctionDetail.quantityUnit}
              </p>
            </div>
            <div className={styles.right}>
              <p className={styles.light}>Highest bid</p>
              <p className={styles.bold}>
                ₹{highestBidAmount}/{auctionDetail.quantityUnit}
              </p>
            </div>
          </section>
          {!auth.loggedInUser && (
            <>
              <Button
                solid={true}
                text="Login to bid"
                buttonClick={() => {
                  // console.log("button clicked");
                  navigate("/login?l=t");
                }}
                buttonStyle={btnStyle}
              />
            </>
          )}

          {auth.loggedInUser &&
            auth.loggedInUser.role === "buyer" &&
            auctionDetail.status === "live" && (
              <>
                <PlaceBid
                  placeholderText="Enter price"
                  loadAuctionDetail={props.loadAuction}
                  asked={auctionDetail.askedPricePerUnit}
                  highbid={highestBidAmount}
                  auctionID={auctionDetail._id}
                  buttonText="Bid"
                  bidIncrementAmount={auctionDetail.bidIncrementAmount}
                  myHighestBid={userHighestBidAmount}
                />
              </>
            )}
          {/* <p className={styles.bidIncrement}>
            Bid increment{" "}
            <span>
              ₹{auctionDetail.bidIncrementAmount}/{auctionDetail.quantityUnit}
            </span>
          </p> */}
          <section className={styles.traceability}>
            <div
              className={styles.traceabilityLeft}
              onClick={() => setOpenTraceability(true)}
            >
              <img src={hederaLogo} alt="hedera logo" />
              <span>Traceability</span>
            </div>
            <div
              className={styles.traceabilityRight}
              onClick={() => setOpenQuality(true)}
            >
              <span>Quality details</span>
            </div>
          </section>
        </section>
      </section>
      {openTraceability ? (
        <Modal
          showModal={openTraceability}
          closeModal={handleCloseTraceabilty}
          modalStyle={widthCheck? modalCardStyle:modalStyle}
        >
          {auctionDetail && (
            <>
              <TraceabilityDetails
                auction={props.auction}
                close={handleCloseTraceabilty}
              />
            </>
          )}
        </Modal>
      ) : null}

      {openQuality ? (
        <Modal
          showModal={openQuality}
          closeModal={handleCloseQuality}
          modalStyle={widthCheck? modalCardStyle:modalStyle}
        >
          {auctionDetail && (
            <>
              <QualityDetails
                auction={props.auction}
                close={handleCloseQuality}
              />
            </>
          )}
        </Modal>
      ) : null}
    </>
  );
}

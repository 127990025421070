import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: null,
  message: null,
  saveAuctionDetailsInProgress: false,
  saveAuctionImagesInProgress: false,
  saveAuctionCollectionDetailsInProgress: false,
  saveQualityDetailsInProgress: false,
  saveTraceabilityDetailsInProgress: false,
  auctionId: null,
};

const auctionCreationSlice = createSlice({
  name: "auctionCreation",
  initialState,
  reducers: {
    saveAuctionDetails(state, action) {
      state.saveAuctionDetailsInProgress = true;
    },
    saveAuctionDetailsSuccess(state, action) {
      state.saveAuctionDetailsInProgress = false;
      state.auctionId = action.payload?.body._id;
    },
    saveAuctionDetailsFailure(state, action) {
      state.saveAuctionDetailsInProgress = false;
    },
    saveAuctionImages(state, action) {
      state.saveAuctionImagesInProgress = true;
    },
    saveAuctionImagesSuccess(state, action) {
      state.saveAuctionImagesInProgress = false;
    },
    saveAuctionImagesFailure(state, action) {
      state.saveAuctionImagesInProgress = false;
    },
    saveAuctionCollectionDetails(state, action) {
      state.saveAuctionCollectionDetailsInProgress = true;
    },
    saveAuctionCollectionDetailsSuccess(state, action) {
      state.saveAuctionCollectionDetailsInProgress = false;
    },
    saveAuctionCollectionDetailsFailure(state, action) {
      state.saveAuctionCollectionDetailsInProgress = false;
    },
    saveQualityDetails(state, action) {
      state.saveQualityDetailsInProgress = true;
    },
    saveQualityDetailsSuccess(state, action) {
      state.saveQualityDetailsInProgress = false;
    },
    saveQualityDetailsFailure(state, action) {
      state.saveQualityDetailsInProgress = false;
    },
    saveTraceabilityDetails(state, action) {
      state.saveTraceabilityDetailsInProgress = true;
    },
    saveTracebilityDetailsSuccess(state, action) {
      state.saveTraceabilityDetailsInProgress = false;
    },
    saveTracebilityDetailsFailure(state, action) {
      state.saveTraceabilityDetailsInProgress = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = auctionCreationSlice;

import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import HerbalDetailForm from "./details";
import ImagesForm from "./images";
import CollectionMethodForm from "./collection";
import TraceabilityForm from "./traceability";
import QualityForm from "./quality";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { auctionCreationSaga } from "./saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";
import { useNavigate } from "react-router";

const CreateAuction = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: auctionCreationSaga });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auctionId = useSelector(selectors.selectAuctionId);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    herbalDetail: "",
    images: "",
    collectionMethod: "",
    traceability: "",
    qualityData: [{ parameter: '', value: '' }],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (field, files) => {
    setFormData((prevData) => ({ ...prevData, [field]: files }));
  };

  const handleQualityInputChange = (qualityData) => {
    setFormData((prevData) => ({ ...prevData, qualityData }));
  };

  const handleStateInputChange = (state) => {
    setFormData((prevData) => ({ ...prevData, state }));
  };

  const handleDistrictInputChange = (district) => {
    setFormData((prevData) => ({ ...prevData, district }));
  };

  useEffect(() => {
   // console.log(formData);
  }, [formData, auctionId]);

  const saveHerbalDetails = () => {
    const payload = {
      data: {
        herbName: formData.herbName,
        herbVariety: formData.variety,
        totalQuantity: formData.quantity,
        quantityUnit: 'Kg',
        askedPricePerUnit: formData.askedPrice,
        bidIncrementAmount: 0,
        packagingType: formData.ptype,
        packagingSize: formData.packSize,
        description: formData.description,
      },
      onSuccess: () => {
        nextStep();
      },
    };
    dispatch(actions.saveAuctionDetails(payload));
  };

  const saveImages = (files) => {
    const payload = {
      data: {
        id: auctionId,
        files,
      },
      onSuccess: () => {
        nextStep();
      },
    };
    dispatch(actions.saveAuctionImages(payload));
  };

  const saveCollectionDetails = () => {
    let data;
    if(formData.type === undefined || formData.type === 'cultivated'){
      data = {
        id: auctionId,
        tehsil: formData.tehsil,
        village: formData.village,
        district: formData.district,
        state: formData.state,
        ret: formData.ret !== undefined ? formData.ret : false,
        type: formData.type !== undefined ? formData.type : 'cultivated',
        reportPatwari: formData.reportPatwari,
        additionalDocument: formData.additionalDoc,
        legalDoc: formData.legalFile
      };
    }else{
      data = {
        id: auctionId,
        forestPermission: formData.forestPermission,
        bmc: formData.bmc,
        fdr: formData.fdr,
        panchayatPermit: formData.panchayatPermit,
        ret: formData.ret !== undefined ? formData.ret : false,
        type: formData.type !== undefined ? formData.type : 'cultivated',
        additionalDocument: formData.additionalDoc,
        legalDoc: formData.legalFile
      };
    }
    console.log(data);
    const payload = {
      data: data,
      onSuccess: () => {
        nextStep();
      },
    };
    dispatch(actions.saveAuctionCollectionDetails(payload));
  };

  const saveQualityDetails = (data) => {
    const payload = {
      data: {
        id: auctionId,
        testLabName: formData.labName,
        testLabDate: formData.labDate,
        labReport: formData.labReport,
        qualityData: formData.qualityData
      },
      onSuccess: () => {
        // console.log(payload)
        nextStep();
        navigate("/dashboard/seller/landing");
      },
    }
    dispatch(actions.saveQualityDetails(payload));
  };

  const saveTraceabilityDetails = () => {
    const payload = {
      data: {
        id: auctionId,
        traceData: [
          {
            detail: "Sowing",
            date: formData.dos,
          },
          {
            detail: "Harvest",
            date: formData.doh,
          },
          {
            detail: "Picking",
            date: formData.dop,
          },
          {
            detail: "Seggregation",
            date: formData.sdo,
          },
          {
            detail: "Grading",
            date: formData.gdo,
          },
        ],
      },
      onSuccess: () => {
        nextStep();
      },
    };
    dispatch(actions.saveTraceabilityDetails(payload));
  };

  /*  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  }; */

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    // Scroll to top when step changes
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <div id="createAuctionDiv">
      <Tabs activeKey={step} onSelect={(key) => setStep(Number(key))} disabled={true}>
        <Tab eventKey={1} title="Herbal detail" disabled={true}>
          <HerbalDetailForm
            formData={formData}
            handleInputChange={handleInputChange}
            //nextStep={nextStep}
            saveHerbalDetails={saveHerbalDetails}
          />
        </Tab>
        <Tab eventKey={2} title="Images" disabled={true}>
          <ImagesForm
            formData={formData}
            handleInputChange={handleInputChange}
            saveImages={saveImages}
            previousStep={previousStep}
          />
        </Tab>
        <Tab eventKey={3} title="Collection method" disabled={true}>
          <CollectionMethodForm
            formData={formData}
            handleInputChange={handleInputChange}
            nextStep={nextStep}
            previousStep={previousStep}
            handleFileChange={handleFileChange}
            saveCollectionDetails={saveCollectionDetails}
            handleStateInputChange={handleStateInputChange}
            handleDistrictInputChange={handleDistrictInputChange}
          />
        </Tab>
        <Tab eventKey={4} title="Traceability" disabled={true}>
          <TraceabilityForm
            formData={formData}
            handleInputChange={handleInputChange}
            saveTraceabilityDetails={saveTraceabilityDetails}
            previousStep={previousStep}
          />
        </Tab>
        <Tab eventKey={5} title="Quality" disabled={true}>
          <QualityForm
            formData={formData}
            handleInputChange={handleInputChange}
            saveQualityDetails={saveQualityDetails}
            previousStep={previousStep}
            handleQualityInputChange={handleQualityInputChange}
            handleFileChange={handleFileChange}
          />
        </Tab>
      </Tabs>

      <div className="mt-3">
        {/* {step > 1 && (
          <Button variant="secondary" className="mr-2" onClick={previousStep}>
            Previous
          </Button>
        )}
        {step < 5 && (
          <Button variant="primary" onClick={nextStep}>
            Next
          </Button>
        )}
        */}
        {/* {step === 5 && (
          <Button variant="success" onClick={handleSubmit}>
            Submit
          </Button>
        )}  */}
      </div>
    </div>
  );
};

export default CreateAuction;

import React from "react";
import successIcon from "../../../../../assets/icons/success.svg";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";

export default function FormSubmittedPage(props) {
  const verifyButtonStyles = {
    root: {
      width: "120px",
      height: "32px",
      borderRadius: "6px",
      backgroundColor: "#E2E8F0 !important",
    },
  };
  const CustomVerifyButton = withStyles(verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );
  return (
    <>
      <div>
        <img src={successIcon} alt="successIcon" />
      </div>
      <div
        style={{
          color: "#000000",
          fontWeight: 500,
          fontSize: "24px",
          lineHeight: "32px",
          marginTop: "32px",
        }}
      >
        Form submitted successfully!
      </div>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "36px",
        }}
      >
        Thank again!
      </div>
      <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "28px",
          marginTop: "36px",
        }}
      >
        We will verify your details and approve your account very soon.
      </div>
      {/* <div
        style={{
          color: "#2D3748",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "28px",
          marginTop: "24px",
        }}
      >
        If you made any mistakes while submitting the form, you can resubmit it
        by clicking the button below. You can also refresh the page to access
        the form again
      </div>
      <div style={{ marginTop: "24px" }}>
        <CustomVerifyButton onClick={props.backToRegistrationPage}>
          <div
            style={{
              color: "#1A202C",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Re-Submit
          </div>
        </CustomVerifyButton>
      </div> */}
    </>
  );
}

import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../services/apis";

export function* handleLogin({ payload }) {
  try {
    const res = yield call(API.login, payload);
    const { data } = res;
    yield put(actions.fetchLoggedInUser());
    yield put(actions.loginSuccess(data));
  } catch (err) {
    yield put(actions.loginFailure(err?.response?.data));
  }
}

export function* handleAdminLogin({ payload }) {
  try {
    console.log('here admin login9999999 failed ehrere');
    const res = yield call(API.login, {
      username: payload.username,
      password: payload.password,
    });
    const { data } = res;
    yield put(actions.fetchLoggedInUser());
    yield put(actions.loginSuccess(data));
    payload.onSuccess();
  } catch (err) {
    console.log('here admin login failed ehrere');
    yield put(actions.loginFailure(err?.response?.data));
  }
}

export function* handleLogout() {
  try {
    const res = yield call(API.logout);
    const { data } = res;
    yield put(actions.fetchLoggedInUser());
    yield put(actions.logoutSuccess(data));
  } catch (err) {
    yield put(actions.logoutFailure(err?.response?.data));
  }
}

export function* handleFetchLoggedInUser({ payload }) {
  try {
    const res = yield call(API.fetchMyProfile, payload);
    let { data } = res;
    data = JSON.parse(data);
    yield put(actions.fetchLoggedInUserSuccess(data));
  } catch (err) {
    yield put(actions.fetchLoggedInUserFailure(err?.response?.data));
  }
}
export function* handleGenerateOtp({ payload }) {
  try {
    const res = yield call(API.generateOtp, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.generateOtpSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.generateOtpFailure(err?.response?.data));
  }
}

export function* handleGenerateLoginOtp({ payload }) {
  try {
    const res = yield call(API.generateOtpForLogin, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.generateLoginOtpSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.generateLoginOtpFailure(err?.response?.data));
  }
}

export function* handleValidateOtp({ payload }) {
  try {
    const res = yield call(API.validateOtp, payload.data);
    let data = JSON.parse(res.data);
    yield put(actions.fetchLoggedInUser());
    yield put(actions.validateOtpSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.validateOtpFailure(err?.response?.data));
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* authSaga() {
  yield takeLatest(actions.login.type, handleLogin);
  yield takeLatest(actions.adminLogin.type, handleAdminLogin);
  yield takeLatest(actions.logout.type, handleLogout);
  yield takeLatest(actions.fetchLoggedInUser.type, handleFetchLoggedInUser);
  yield takeLatest(actions.generateOtp.type, handleGenerateOtp);
  yield takeLatest(actions.generateLoginOtp.type, handleGenerateLoginOtp);
  yield takeLatest(actions.validateOtp.type, handleValidateOtp);
}

import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Modal from "../modal";
import QuickAuctionView from "../quickAuctionView";
import TraceabilityDetails from "../traceability";
import QualityDetails from "../qualityDetails";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import routes from "../../../../routes";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import { useAuth } from "../../../contexts/auth";
import herbAllAuctions from "../../../../assets/homeImages/herbAllAuctions.png";
import roles from "../../../constants/roles";



const modalStyle = {
  width: "600px",
  height: "100%",
  borderRadius: "0",
  left: "100%",
  top: "50%",
  transform: "translate(-100%, -50%)",
  padding: "0",
};
const modalCardStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "0",
  left: "100%",
  top: "50%",
  transform: "translate(-100%, -50%)",
  padding: "0",

};

export default function Card({ url, herbName, price, unit, auctionId }) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();
  const auth = useAuth();

  const [showQuickView, setShowQuickView] = useState(false);

  const [openTraceability, setOpenTraceability] = useState(false);

  const [openQuality, setOpenQuality] = useState(false);


  const handleCloseTraceabilty = () => {
    setOpenTraceability(false);
  };

  const handleCloseQuality = () => {
    setOpenQuality(false);
  };
  const handleQuickView = () => {
    setShowQuickView(!showQuickView);
    //Call auction detail api
    if (auth.loggedInUser) {
      dispatch(actions.fetchAuctionDetail(auctionId));
    } else {
      dispatch(actions.fetchAuctionDetailforUnAuthenticated(auctionId));
    }
  };

  const handleclose = () => {
    setShowQuickView(false);
  };

  

  const fetchQuickViewAuctionDetail = (id) => {
    if (auth.loggedInUser) {
      dispatch(actions.fetchAuctionDetail(auctionId));
    } else {
      dispatch(actions.fetchAuctionDetailforUnAuthenticated(auctionId));
    }
  };

  const auctionDetail = useSelector(selectors.selectliveauctions) || {
    herbName: "",
    herbVariety: "",
  };
  let auctionUrl;

  if (typeof url === "string") {
    auctionUrl = url;
  } else {
    if (url.images.length > 0) {
      if (url.images[0].imageUrl !== undefined) {
        auctionUrl = url.images[0].imageUrl;
      } else {
        auctionUrl = herbAllAuctions;
      }
    } else {
      auctionUrl = herbAllAuctions;
    }
  }

  // useEffect(()=>{
  //   return () => {
  //     dispatch(actions.clearStore());
  //   }  

  // },[]);
  
  const widthCheck = window.innerWidth <= 768;

  return (
    <>
      <div className={styles.card}>
        <Link
          to={reverse(`${routes.home.auctions.auctionDetails}`, {
            id: auctionId,
          })}
        >
          <div className={styles.imageContainer}>
            <img src={auctionUrl} alt={herbName} style={{ width: "100%" }} />
            <span className={styles.liveBadge}>Live</span>
          </div>
          <div className={styles.herbDetails}>
            <h3>{herbName}</h3>
            <p>
              Ask Price{" "}
              <span>
                ₹{price}/{unit}
              </span>
            </p>
          </div>

          <button>{(auth && auth.loggedInUser && auth.loggedInUser.role === roles.BUYER.name) ? 'Place bid': 'View Detail'}</button>
        </Link>
        <p className={styles.quick} onClick={handleQuickView}>
          Quick view
        </p>
      </div>
      {showQuickView ? (
        <Modal
          closeButton={true}
          showModal={showQuickView}
          closeModal={handleclose}
          modalStyle={{ width: "90%"}}
        >
          {auctionDetail && (
            <QuickAuctionView
              auction={auctionDetail}
              setOpenTraceability={setOpenTraceability}
              setOpenQuality={setOpenQuality}
              fetchQuickViewAuctionDetail={fetchQuickViewAuctionDetail}
            />
          )}
        </Modal>
      ) : null}

      {openTraceability ? (
        <Modal
          showModal={openTraceability}
          closeModal={handleCloseTraceabilty}
          modalStyle={widthCheck? modalCardStyle:modalStyle}
        >
          <TraceabilityDetails
            auction={auctionDetail}
            close={handleCloseTraceabilty}
          />
        </Modal>
      ) : null}

      {openQuality ? (
        <Modal
          showModal={openQuality}
          closeModal={handleCloseQuality}
          modalStyle={widthCheck? modalCardStyle:modalStyle}
        >
          <QualityDetails auction={auctionDetail} close={handleCloseQuality} />
        </Modal>
      ) : null}
    </>
  );
}

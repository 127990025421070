import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import SImage from "../../../shared/Image";
import styles from "./sidebar.module.css";
import IoHammerOutline from "../../../../../assets/dashboardImages/IoHammerOutline.svg";
import FiUser from "../../../../../assets/dashboardImages/FiUser.svg";
import BiUser from "../../../../../assets/dashboardImages/Biuser.svg";
import { Link } from 'react-router-dom';


const AdminDashboardSidebar = ({ isOpen , toggleSidebar}) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleNavClick = () => {
    if(isMobile){
      toggleSidebar();
    }   
  };

  const handleResize = () => {
    const widthCheck = window.innerWidth <= 768;
    setIsMobile(widthCheck);
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sidebarStyles = {
    height: "100vh",
    width: isMobile ? "100%":"250px",
    backgroundColor: "#171923",
    position: "fixed",
    top: "0",
    left: isOpen ? "0px" : isMobile? "100%":"-250px",
    transition: "all 0.3s ease-out",
    padding: "20px",
    paddingTop: "6em",
    zIndex: "1",
  };

  return (
    <div style={sidebarStyles} className="userDashboard">
      <Nav
        defaultActiveKey="/dashboard/admin/auctions"
        className={[styles.sidenav, "flex-column"].join(" ")}
      >
        
        <Link className='nav-link' to="/dashboard/admin/auctions" onClick={handleNavClick}>
          <SImage avatar={IoHammerOutline} pclass={styles.iconImg}/>Auctions
          </Link>

        {/* <Nav.Link href="/dashboard/admin/suppliers">
          <SImage avatar={FiUser} pclass={styles.iconImg} />
          Suppliers
        </Nav.Link> */}

        <Link className='nav-link' to="/dashboard/admin/suppliers" onClick={handleNavClick}>
          <SImage avatar={FiUser} pclass={styles.iconImg}/>Suppliers
          </Link>

        {/* <Nav.Link href="/dashboard/admin/buyers">
          <SImage avatar={BiUser} pclass={styles.iconImg} />
          Buyers
        </Nav.Link> */}

        <Link className='nav-link' to="/dashboard/admin/buyers" onClick={handleNavClick}>
          <SImage avatar={BiUser} pclass={styles.iconImg}/>Buyers
          </Link>

      </Nav>
    </div>
  );
};

export default AdminDashboardSidebar;

// const getClasses = () => {
//   const navLinks = document.querySelectorAll('.nav-link');
// }

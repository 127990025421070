import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import DashboardHeader from "../layout/header";
import BuyerDashboardSidebar from "../layout/sidebar";
import SellerDashboardSidebar from "../layout/sellerSidebar";
import AdminDashboardSidebar from "./adminSidebar";
import { useAuth } from "../../../contexts/auth";

const MainDashboardLayout = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const auth = useAuth();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [ isCount, setIsCount] = useState(0)
  const widthCheck = window.innerWidth <= 768;
  const contentAreaStyles = {
    padding: widthCheck? "5px" : "25px",
    marginLeft: isOpen ? "250px" : "0",
    transition: "all 0.3s ease-out",
    backgroundColor: "#F7FAFC",
    minHeight: "100vh",
  };
  console.log(isCount, isOpen) ;

  useEffect(() => {
    if(widthCheck && isCount == 0){
      setIsOpen(!isOpen);
      setIsCount(1);
    }
  }, [isCount]);
  
  return (
    <main>
      <>
        {auth.loggedInUser && (
          <>
            <DashboardHeader toggleSidebar={toggleSidebar} />
            {auth.loggedInUser && auth.loggedInUser.role === "buyer" && (
              <>
                <BuyerDashboardSidebar isOpen={isOpen} toggleSidebar={toggleSidebar}/>
              </>
            )}

            {auth.loggedInUser && auth.loggedInUser.role === "seller" && (
              <>
                <SellerDashboardSidebar isOpen={isOpen} toggleSidebar={toggleSidebar}/>
              </>
            )}

            {auth.loggedInUser && auth.loggedInUser.role === "super_admin" && (
              <>
                <AdminDashboardSidebar isOpen={isOpen} toggleSidebar={toggleSidebar}/>
              </>
            )}
          </>
        )}
      </>

      <div style={contentAreaStyles}>{props.children}</div>
    </main>
  );
};

export default MainDashboardLayout;

import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import SImage from '../../../shared/Image';
import styles from './sidebar.module.css';
import dasboardImg from '../../../../../assets/dashboardImages/dashboard.svg';
import proImg from '../../../../../assets/dashboardImages/profile.svg';
import liveImg from '../../../../../assets/dashboardImages/live.svg';
import closeImg from '../../../../../assets/dashboardImages/close.svg';
import downImg from '../../../../../assets/dashboardImages/downa.svg';
import cancelImg from '../../../../../assets/dashboardImages/cancel.svg';
import { Link } from 'react-router-dom';

// const navStyle = {
//   active: {
//     backgroundColor: "red"
//   }
// }

const DashboardSidebar= ({ isOpen, toggleSidebar }) => {
  const [showOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const handleNavClick = () => {
    if(isMobile){
      toggleSidebar();
    }   
  };
  const handleNestedNavClick = () => {
    setIsOpen(!showOpen);  
  };

  const handleResize = () => {
    const widthCheck = window.innerWidth <= 768;
    setIsMobile(widthCheck);
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sidebarStyles = {
    height: "100vh",
    width: isMobile ? "100%":"250px",
    backgroundColor: "#171923",
    position: "fixed",
    top: "0",
    left: isOpen ? "0px" : isMobile? "100%":"-250px",
    transition: "all 0.3s ease-out",
    padding: "20px",
    paddingTop: "6em",
    zIndex: "1",
  };
  const location = window.location.pathname;
  return (
    <div style={sidebarStyles} className="userDashboard">
      <Nav defaultActiveKey="/dashboard/buyer/landing" className={[styles.sidenav, "flex-column"].join(' ')}>
        <Link className={(location === "/dashboard/buyer/landing")? 'nav-link active': 'nav-link'}   to="/dashboard/buyer/landing" onClick={handleNavClick}><SImage avatar={dasboardImg} pclass={styles.iconImg}/>Dashboard</Link>
        <Link className={(location === "/dashboard/buyer/profile")? 'nav-link active': 'nav-link'}   to="/dashboard/buyer/profile" onClick={handleNavClick}><SImage avatar={proImg} pclass={styles.iconImg}/>My profile</Link>
        <Link className={(location === "/dashboard/buyer/live-auctions")? 'nav-link active': 'nav-link'}   to="/dashboard/buyer/live-auctions" onClick={handleNavClick}><SImage avatar={liveImg} pclass={styles.iconImg}/>Live auctions</Link>
        <Link className='nav-link' to="#" onClick={handleNestedNavClick}>
          <SImage avatar={closeImg} pclass={styles.iconImg}/>Closed auctions <SImage avatar={downImg} pclass={styles.iconImg2}/>
        </Link>
        {showOpen && (
          <div style={{ paddingLeft: '20px'}}>
            <Link className={(location === "/dashboard/buyer/won-auctions")? 'nav-link active': 'nav-link'}   to="/dashboard/buyer/won-auctions" onClick={handleNavClick}>Won auction</Link>
            <Link className={(location === "/dashboard/buyer/lost-auctions")? 'nav-link active': 'nav-link'}   to="/dashboard/buyer/lost-auctions" onClick={handleNavClick}>Lost auctions</Link>
            <Link className={(location === "/dashboard/buyer/pending-awards")? 'nav-link active': 'nav-link'}   to="/dashboard/buyer/pending-awards" onClick={handleNavClick}>Pending awards</Link>
          </div>
        )}
        
        <Link className={(location === "/dashboard/buyer/cancelled-auctions")? 'nav-link active': 'nav-link'}  to="/dashboard/buyer/cancelled-auctions" onClick={handleNavClick}><SImage avatar={cancelImg} pclass={styles.iconImg}/>Cancelled auctions</Link>
      </Nav>
    </div>
  );
};

export default DashboardSidebar;

// const getClasses = () => {
//   const navLinks = document.querySelectorAll('.nav-link');
// }

import React from "react";
import styles from "./styles.module.css";
import fbLogo from "../../../../assets/icons/facebook.svg";
import twitterLogo from "../../../../assets/icons/twitter.svg";
import socialLogo from "../../../../assets/icons/social.svg";

export default function Footer() {
  return (
    <>
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerContentImage}>
          <div className={styles.footerImage}>
            <img
              loading="lazy"
              src={fbLogo}
              className={styles.img5} alt="facebook logo"
            />
            <img
              loading="lazy"
              src={twitterLogo} alt="twitter logo"
              className={styles.img6}
            />
            <img
              loading="lazy"
              src={socialLogo} alt="social logo"
              className={styles.img7}
            />
          </div>
        </div>
        <div className={styles.footerMenu}>
          <ul className={styles.footerLinks}>
            <li className={styles.footerFont}>Disclaimer</li>
            <li className={styles.footerFont}>Market Price</li>
            <li className={styles.footerFont}>FAQ</li> 
            <li className={styles.footerFont}>View Feedback</li>
            <li className={styles.footerFont}>Privacy Policy</li>
          </ul>
        </div>
        <div className={styles.footerAddress}>
          Centre of Excellence RIISM, Joginder Nagar, Himachal Pradesh 175015
        </div>
      </div>
      <div className={styles.footerCopyright}>
        &copy; {new Date().getFullYear()} JadiButiBazar | Powered by AgNext Technologies
      </div>
    </div>
    </>
  );
}

import React, { useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import styles from "./placebid.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { toast } from "react-toastify";

import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import { number } from "yup";
import {
  toastCustomConfirm,
  toastSuccess,
  toastWarning,
} from "../../../utils/toastHelper";
import { useAuth } from "../../../contexts/auth";

const PlaceBid = ({
  placeholderText,
  asked,
  highbid,
  auctionID,
  buttonText,
  loadAuctionDetail,
  bidIncrementAmount,
  myHighestBid,
  fetchAllAuction,
}) => {
  const auth = useAuth();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();
  const sucessBid = useSelector(
    selectors.postnewBid
  );

  


  const makeBid = function (event) {
    event.preventDefault();
    if (isNaN(asked)) {
      toastWarning("Invalid Asked Amount");
      return;
    }

    if (asked <= 0) {
      toastWarning("Invalid Seller Asked Amount");
      return;
    }

    let el = document.getElementById(auctionID);

    if (isNaN(el.value) || !el.value) {
      toastWarning("Invalid Amount");
      return;
    }

    let amount = parseFloat(el.value);

    if (amount < asked) {
      toastWarning("Bid Amount must be greater than Ask Price");
      return;
    }

    if (myHighestBid && amount <= myHighestBid) {
      toastWarning(`Bid amount must be greater than ${myHighestBid}`);
      return;
    }

    let postObject = {
      auctionId: auctionID,
      amount: amount,
      onSuccess: () => {
         toastSuccess("Bid placed successfully");
         if (loadAuctionDetail) {
          loadAuctionDetail(auctionID);
        }
    
        if (fetchAllAuction) {
          fetchAllAuction();
        }
      },
    };

    dispatch(actions.postBid(postObject));
    el.value = "";

    // if (loadAuctionDetail) {
    //   loadAuctionDetail(auctionID);
    // }

    // if (fetchAllAuction) {
    //   fetchAllAuction();
    // }
  };

  return (
    <Form className={styles.placeBidFlex}>
      <Row>
        <Col xs={7}>
          <input type="hidden" value={auctionID} />
          <Form.Control
            id={auctionID}
            type="text"
            placeholder={placeholderText}
            className={styles.placeColor}
            
          />
        </Col>
        <Col xs={3}>
          {auth.loggedInUser && auth.loggedInUser.role === "buyer" && (
            <Button
              style={{
                backgroundColor: "#38A169",
                outline: "none",
                border: "none",
              }}
              onClick={makeBid}
              className={styles.bidBtn}
              type="submit"
            >
              {buttonText}
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default PlaceBid;

import React, {useState} from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import styles from "./style.module.css";

const TraceabilityForm = ({
  formData,
  handleInputChange,
  saveTraceabilityDetails,
  previousStep,
}) => {
  const [sowingError, setSowingError] = useState(null);
  const [harvestingError, setHarvestingError] = useState(null);
  const [pickingError, setPickingError] = useState(null);
  const [doneError, setDoneError] = useState(null);
  const [gradingError, setGradingError] = useState(null);

  const [formValidated, setFormValidated] = useState(false);
  const handleNext = () => {
    if (!formData.dos) {
      setSowingError("Please select sowing date.");
    } else{
      setSowingError(null);
    }
    if (!formData.doh) {
      setHarvestingError("Please enter date of harvesting.");
    } else{
      setHarvestingError(null);
    }
    if (!formData.dop) {     
      setPickingError("Please enter date of picking.");
    } else{
      setPickingError(null);
    }
    if (!formData.sdo) {      
      setDoneError("Please enter Segregation.");
    } else{
      setDoneError(null);
    }
    if (!formData.gdo) {
      setGradingError("Please enter a grade date.");
    } else {
      setGradingError(null);
    }

    if(formData.dos && formData.dop && formData.doh && formData.sdo && formData.gdo){
      // Form is valid, perform submission
      saveTraceabilityDetails();
    }
    
  };

  const localhandleOnblur = (event) => {
    setErrorMessage(event.target.id);
  };

  const setErrorMessage = (id)=>{
    
      switch(id){
        case "dos":
          if (!formData.dos) {
            setSowingError("Please select sowing date.");
          } else{
            setSowingError(null);
          }
        break;
        case "doh":
        if (!formData.doh) {
          setHarvestingError("Please enter date of harvesting.");
        } else{
          setHarvestingError(null);
        }
        break;
        case "dop":
        if (!formData.dop) {     
          setPickingError("Please enter date of picking.");
        } else{
          setPickingError(null);
        }
        break;
        case "sdo":
        if (!formData.sdo) {      
          setDoneError("Please enter Segregation.");
        } else{
          setDoneError(null);
        }
        break;
        case "gdo":
        if (!formData.gdo) {
          setGradingError("Please enter a grade date.");
        } else {
          setGradingError(null);
        }
        break;

      }
  }


  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <h3 className="formheadingText">Traceability details</h3>
      <Row className="mt-3 mb-3">
        <Col lg={3}>
          <Form.Group controlId="dos">
            <Form.Label>
              <span className="headingText">Approx. date of sowing</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="dos"
              value={formData.dos}
              onChange={handleInputChange}
              onBlur={localhandleOnblur}
              className={`form-control ${sowingError && 'is-invalid'} ${
                sowingError === false && 'is-valid'
              }`}
              required
            />
            <small className={`${sowingError && 'invalid-feedback'} `}>
              {!!sowingError && sowingError}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="doh">
            <Form.Label>
              <span className="headingText">Approx. date of harvesting</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="doh"
              value={formData.doh}
              onChange={handleInputChange}
              onBlur={localhandleOnblur}
              className={`form-control ${harvestingError && 'is-invalid'} ${
                harvestingError === false && 'is-valid'
              }`}
              required
            />
            <small className={`${harvestingError && 'invalid-feedback'} `}>
              {!!harvestingError && harvestingError}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="dop">
            <Form.Label>
              <span className="headingText">Approx. date of picking</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="dop"
              value={formData.dop}
              onChange={handleInputChange}
              onBlur={localhandleOnblur}
              className={`form-control ${pickingError && 'is-invalid'} ${
                pickingError === false && 'is-valid'
              }`}
              required
            />
            <small className={`${pickingError && 'invalid-feedback'} `}>
              {!!pickingError && pickingError}
            </small>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="sdo">
            <Form.Label>
              <span className="headingText">Segregation done on</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="sdo"
              value={formData.sdo}
              onChange={handleInputChange}
              onBlur={localhandleOnblur}
              className={`form-control ${doneError && 'is-invalid'} ${
                doneError === false && 'is-valid'
              }`}
              required
            />
            <small className={`${doneError && 'invalid-feedback'} `}>
              {!!doneError && doneError}
            </small>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="gdo">
            <Form.Label>
              <span className="headingText">Grading done on</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="gdo"
              value={formData.gdo}
              onChange={handleInputChange}
              onBlur={localhandleOnblur}
              className={`form-control ${gradingError && 'is-invalid'} ${
                gradingError === false && 'is-valid'
              }`}
              required
            />
            <small className={`${gradingError && 'invalid-feedback'} `}>
              {!!gradingError && gradingError}
            </small>
          </Form.Group>
        </Col>

        <Col lg={12} className="mt-5">
          <div className="float-end">
            <Button variant="secondary" onClick={previousStep}>
              Previous
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TraceabilityForm;

import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import SImage from '../../../shared/Image';
import styles from './sidebar.module.css';
import dasboardImg from '../../../../../assets/dashboardImages/dashboard.svg';
import proImg from '../../../../../assets/dashboardImages/profile.svg';
import liveImg from '../../../../../assets/dashboardImages/live.svg';
import closeImg from '../../../../../assets/dashboardImages/close.svg';
import downImg from '../../../../../assets/dashboardImages/downa.svg';
import cancelImg from '../../../../../assets/dashboardImages/cancel.svg';
import eyeImg from '../../../../../assets/dashboardImages/eye.svg';
import createImg from '../../../../../assets/dashboardImages/create.svg';
import draftImg from '../../../../../assets/dashboardImages/draft.svg';

// const navStyle = {
//   active: {
//     backgroundColor: "red"
//   }
// }

const SellerDashboardSidebar= ({ isOpen, toggleSidebar }) => {
  const [showOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const handleNavClick = () => {
    if(isMobile){
      toggleSidebar();
    }   
  };
  const handleNestedNavClick = () => {
    setIsOpen(!showOpen);  
  };

  const handleResize = () => {
    const widthCheck = window.innerWidth <= 768;
    setIsMobile(widthCheck);
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sidebarStyles = {
    height: "100vh",
    width: isMobile ? "100%":"250px",
    backgroundColor: "#171923",
    position: "fixed",
    top: "0",
    left: isOpen ? "0px" : isMobile? "100%":"-250px",
    transition: "all 0.3s ease-out",
    padding: "20px",
    paddingTop: "6em",
    zIndex: "1",
  };
  const location = window.location.pathname;

  return (
    <div style={sidebarStyles} className="userDashboard">
      <Nav defaultActiveKey="/dashboard/seller/landing" className={[styles.sidenav, "flex-column"].join(' ')}>
        <Link className={(location === "/dashboard/seller/landing")? 'nav-link active': 'nav-link'} to="/dashboard/seller/landing" onClick={handleNavClick}><SImage avatar={dasboardImg} pclass={styles.iconImg}/>Dashboard</Link>
        <Link className={(location === "/dashboard/seller/profile")? 'nav-link active': 'nav-link'} to="/dashboard/seller/profile" onClick={handleNavClick}><SImage avatar={proImg} pclass={styles.iconImg}/>My profile</Link>
        <Link className={(location === "/dashboard/seller/create-auction")? 'nav-link active': 'nav-link'} to="/dashboard/seller/create-auction" onClick={handleNavClick}><SImage avatar={createImg} pclass={styles.iconImg}/>Create auction</Link>
        <Link className={(location === "/dashboard/seller/live-auctions")? 'nav-link active': 'nav-link'} to="/dashboard/seller/live-auctions" onClick={handleNavClick}><SImage avatar={liveImg} pclass={styles.iconImg}/>Live auctions</Link>
        <Link className={(location === "/dashboard/seller/inreview-auctions")? 'nav-link active': 'nav-link'} to="/dashboard/seller/inreview-auctions" onClick={handleNavClick}><SImage avatar={eyeImg} pclass={styles.iconImg}/>In-review auctions</Link>
        <Link className={(location === "/dashboard/seller/reject-auctions")? 'nav-link active': 'nav-link'} to="/dashboard/seller/reject-auctions" onClick={handleNavClick}><SImage avatar={eyeImg} pclass={styles.iconImg}/>Rejected auctions</Link>
        <Link className='nav-link' to="#" onClick={handleNestedNavClick}>
          <SImage avatar={closeImg} pclass={styles.iconImg}/>Closed auctions <SImage avatar={downImg} pclass={styles.iconImg2}/>
        </Link>
        {showOpen && (
          <div style={{ paddingLeft: '20px'}}>
            <Link className={(location === "/dashboard/seller/awarded-auctions")? 'nav-link active': 'nav-link'} to="/dashboard/seller/awarded-auctions" onClick={handleNavClick} >Awarded auction</Link>
            <Link className={(location === "/dashboard/seller/pending-awards")? 'nav-link active': 'nav-link'} to="/dashboard/seller/pending-awards" onClick={handleNavClick}>Pending awards</Link>
          </div>
        )}
        
        <Link className={(location === "/dashboard/seller/cancelled-auctions")? 'nav-link active': 'nav-link'} to="/dashboard/seller/cancelled-auctions" onClick={handleNavClick}><SImage avatar={cancelImg} pclass={styles.iconImg}/>Cancelled auctions</Link>
        <Link className={(location === "/dashboard/seller/draft-auctions")? 'nav-link active': 'nav-link'} to="/dashboard/seller/draft-auctions" onClick={handleNavClick}><SImage avatar={draftImg} pclass={styles.iconImg}/>Draft auctions</Link>
      </Nav>
    </div>
  );
};

export default SellerDashboardSidebar;

// const getClasses = () => {
//   const navLinks = document.querySelectorAll('.nav-link');
// }

import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./style.module.css";
import MyBid from "../../../../components/dashboardApp/myBidTable";
import { formattedDateTime } from "../../../../utils/helper";

const Overview = ({auctionDetail,highestBidAmount}) => {
    const listData = [];
    const listHeading = [{
        date: 'Date and time',
        mybid: 'Bid price',
    }];

    for (let i = 0; i < 5; i++) {
        listData.push({
            date: "February 22, 2023 13:13 am",
            mybid: "₹40"+i,
        });
    }
    return (
        <>
        <Row style={{marginTop:'2em',marginBottom:'2em'}}className={styles.rowStyle}>
            <Col lg={1} className={styles.w10}>
                <p className={styles.textHeading}>Status</p>
                <p>{auctionDetail.status}</p>
            </Col>
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>No of bids</p>
                <p className={styles.valueText}>{auctionDetail.bids.length}</p>
            </Col>
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>Total qty</p>
                <p>{auctionDetail.totalQuantity}{auctionDetail.quantityUnit}</p>
            </Col>
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>Ask price/{auctionDetail.quantityUnit}</p>
                <p>{auctionDetail.askedPricePerUnit}</p>
            </Col>
            <Col lg={1} className={styles.w10}>
                <p className={styles.textHeading}>H1 bid/kg</p>
                <p>{highestBidAmount}</p>
            </Col>
            {/* <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>My bid/kg</p>
                <p>2</p>
            </Col> */}
            <Col lg={2} className={styles.w17}>
                <p className={styles.textHeading}>Launch date</p>
                <p>{auctionDetail.launchDate ? formattedDateTime(auctionDetail.launchDate) : '--'}</p>
            </Col>
        </Row>
        <h3> Bids</h3>
        <Row className={styles.rowStyle}>
            <MyBid listData={auctionDetail.bids} listHeading={listHeading} auctionDetail={auctionDetail} />
        </Row>
        </>
    );
};

export default Overview;
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: null,
  hasValidateOtpError: false,
  message: null,
  loading: false,
  saveContactDetailsInProgress: false,
  savePersonalDetailsInProgress: false,
  saveLegalDetailsInProgress: false,
  saveAddressDetailsInProgress: false,
  saveBankDetailsInProgress: false,
  generateOtpInProgress: false,
  validateOtpInProgress: false,
  userId: null,
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    saveContactDetails(state, action) {
      state.saveContactDetailsInProgress = true;
    },
    saveContactDetailsSuccess(state, action) {
      state.saveContactDetailsInProgress = false;
      state.userId = action.payload?.data._id;
    },
    saveContactDetailsFailure(state, action) {
      state.saveContactDetailsInProgress = false;
    },
    savePersonalDetails(state, action) {
      state.savePersonalDetailsInProgress = true;
    },
    savePersonalDetailsSuccess(state, action) {
      state.savePersonalDetailsInProgress = false;
    },
    savePersonalDetailsFailure(state, action) {
      state.savePersonalDetailsInProgress = false;
    },
    saveLegalDetails(state, action) {
      state.saveLegalDetailsInProgress = true;
    },
    saveLegalDetailsSuccess(state, action) {
      state.saveLegalDetailsInProgress = false;
    },
    saveLegalDetailsFailure(state, action) {
      state.saveLegalDetailsInProgress = false;
    },
    saveAddressDetails(state, action) {
      state.saveAddressDetailsInProgress = true;
    },
    saveAddressDetailsSuccess(state, action) {
      state.saveAddressDetailsInProgress = false;
    },
    saveAddressDetailsFailure(state, action) {
      state.saveAddressDetailsInProgress = false;
    },
    saveBankDetails(state, action) {
      state.saveBankDetailsInProgress = true;
    },
    saveBankDetailsSuccess(state, action) {
      state.saveBankDetailsInProgress = false;
    },
    saveBankDetailsFailure(state, action) {
      state.saveBankDetailsInProgress = false;
    },
    generateOtp(state, action) {
      state.generateOtpInProgress = true;
    },
    generateOtpSuccess(state, action) {
      state.generateOtpInProgress = false;
    },
    generateOtpFailure(state, action) {
      state.generateOtpInProgress = false;
    },
    validateOtp(state, action) {
      state.validateOtpInProgress = true;
    },
    validateOtpSuccess(state, action) {
      state.validateOtpInProgress = false;
      state.hasValidateOtpError = false;
    },
    validateOtpFailure(state, action) {
      state.validateOtpInProgress = false;
      state.hasValidateOtpError = true;
    }
  },
});

export const { actions, reducer, name: sliceKey } = registrationSlice;

import React, { useEffect,useState } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Heading from "../../../components/shared/Heading";
import Overview from "./overview";
import Traceability from "./traceability";
import Quality from "./quality";
import Supplier from "./supplier";
import Detail from "./detail";
import Payment from "./payment";
import { Link, useNavigate } from "react-router-dom";
import styles from "./style.module.css";
import rightArrow from "../../../../assets/dashboardImages/rightArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import Modal from "../../../components/homeApp/modal";
import ValidateAuctionDetails from "../../validateAuctionDetail";
import { formatedAuctionId } from "../../../utils/helper";

const modalStyle = {
  
};

const AuctionDetail = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const qualityData = [];
  for (let i = 0; i < 4; i++) {
    qualityData.push({
      parameter: "Parameter",
      value: "Value",
    });
  }
  const qualityHeading = [
    {
      parameter: "Parameter",
      value: "Value",
    },
  ];

  const validateAuction = ()=>{
    setvalidateView(!validateView);
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("id");
    dispatch(actions.fetchAuctionDetail(id));
  }, [dispatch]);

  const detail = useSelector(selectors.selectbuyerauction) || {};
  const [validateView, setvalidateView] = useState(false);

  let buyerAuction = detail.auctionDetail;

  return (
    <div>
      <Container fluid id="auctionD">
        {buyerAuction && (
          <Row style={{ marginBottom: "2em" }}>
            <Col lg={1}>
              <Link onClick={(e)=>{navigate(-1)}}>
                <img src={rightArrow} alt="" />
              </Link>
            </Col>
            <Col lg={8}>
              <Heading text={buyerAuction.herbName} />
              <span className="auctionID">{formatedAuctionId(buyerAuction.auctionIdForDisplay ? buyerAuction.auctionIdForDisplay : buyerAuction._id)}</span>
            </Col>
            <Col lg={3}>
              <Link
                // to={"/auctions/detail?id=" + buyerAuction._id}
                to={"/auctions/auctionDetail/" + buyerAuction._id}
                className={[styles.visitBtn, "float-end"].join(" ")}
              >
                Visit auction
              </Link>
              {/* <button
                  onClick={validateAuction}
                  className={[styles.visitBtn, "float-end"].join(" ")}
                >
                  Validate
                </button> */}
            </Col>

            <div>
              <Tabs
                defaultActiveKey="overview"
                id="justify-tab-example"
                className={[styles.tabDiv, "mb-3"].join(" ")}
                justify
              >
                <Tab eventKey="overview" title="Overview">
                  <Overview
                    auction={buyerAuction}
                    highestBidAmount={detail.highestBidAmount}
                    myBids={detail.myBids}
                    userHighestBidAmount={detail.userHighestBidAmount}
                  />
                </Tab>
                <Tab eventKey="traceability" title="Traceability">
                    <Traceability auctionDetail={buyerAuction}/>
                </Tab>
                <Tab eventKey="qualityDetail" title="Quality details">
                    <Quality listHeading={qualityHeading} listData={qualityData} auctionDetail={buyerAuction} />
                </Tab>
              {(buyerAuction && buyerAuction.status ==="awarded") && <Tab eventKey="seller" title="Seller">                    
                    <Supplier auction={buyerAuction}/>
                </Tab>}
                {/* <Tab eventKey="payment" title="Payment and delivery">
                  <Payment />
                </Tab> */}
                {/* <Tab eventKey="auctionDetail" title="Auction details">
                            <Detail />
                        </Tab>
                        <Tab eventKey="traceability" title="Traceability">
                            <Traceability />
                        </Tab>
                        <Tab eventKey="qualityDetail" title="Quality Details">
                            <Quality listHeading={qualityHeading} listData={qualityData} />
                        </Tab>
                        <Tab eventKey="supplierDetail" title="Supplier Details">
                            <Supplier />
                        </Tab> */}
              </Tabs>
            </div>
          </Row>
        )}
      </Container>

      {validateView ? (
        <Modal
          showModal={validateView}
          closeModal={validateAuction}
          modalStyle={styles.modalStyle}
        >
          {buyerAuction && (
            <>
              <ValidateAuctionDetails
                auction={buyerAuction}
                close={validateAuction}
              />
            </>
          )}
        </Modal>
      ) : null}
    </div>
  );
};

export default AuctionDetail;

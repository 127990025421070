import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { myProfileSaga } from "../saga";
import { sliceKey, reducer, actions } from "../slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "../selectors";
import { Col, Row, Form } from "react-bootstrap";
import editIcon from "../../../../../assets/icons/edit_icon.svg";
import SelectCountry from "../../../shared/select/selectCountry";
import SelectState from "../../../shared/select/selectState";
import SelectCity from "../../../shared/select/selectCity";
import Button from "../../../shared/button";
import deleteIcon from "../../../../../assets/icons/deleteIcon.svg";
import Box from "@mui/material/Box";
import FileIcon from "../../../../../assets/icons/file.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/auth";
import { toast } from "react-toastify";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";

const containerStyle = {
  border: "2px dashed #E2E8F0",
  backgroundColor: "#F7FAFC",
  height: "98px",
  paddingLeft: "150px",
  paddingTop: "25px",
  borderRadius: "6px",
};
const fileContainerStyle = {
  border: "1px solid #EDF2F7",
  borderRadius: "6px",
  height: "48px",
  paddingTop: "12px",
  paddingLeft: "15px",
  paddingRight: "15px",
};

const AddressDetail = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myProfileSaga });

  const dispatch = useDispatch();

  const auth = useAuth();

  const { address, country, state, district, pincode, addressProofUrl } =
    useSelector(selectors.addressDetails);

  const [newAddress, setNewAddress] = useState("");

  const [addressError, setAddressError] = useState("");

  const [newCountry, setNewCountry] = useState({ id: "", label: "India" });

  const [countryError, setCountryError] = useState("");

  const [newState, setNewState] = useState({ id: "", label: "" });

  const [stateError, setStateError] = useState("");

  const [newDistrict, setNewDistrict] = useState({ id: "", label: "" });

  const [districtError, setDistrictError] = useState("");

  const [newPincode, setNewPincode] = useState("");

  const [pinCodeError, setPinCodeError] = useState("");

  const [fileToUpload, setFileToUplopad] = useState(null);

  // const [newAddressProofUrl, setNewAddressProofUrl] = useState(addressProofUrl);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setNewAddress(address);
    setNewCountry({ label: country, id: "" });
    setNewState({ label: state, id: "" });
    setNewDistrict({ label: district, id: "" });
    setNewPincode(pincode);
  }, [address, country, state, district, pincode]);

  const handleSubmit = () => {
    if (newAddress.trim() === "") {
      setAddressError("Address is required.");
    }
    if (newCountry.label.trim() === "") {
      setCountryError("Country is required.");
    }
    if (newState.label.trim() === "") {
      setStateError("State is required.");
    }
    if (newDistrict.label.trim() === "") {
      setDistrictError("City is required.");
    }
    if (newPincode.trim() === "") {
      setPinCodeError("Pincode is required.");
    }
    if (
      newAddress !== "" ||
      newCountry.label !== "" ||
      newState.label !== "" ||
      newDistrict.label !== "" ||
      newPincode !== ""
    ) {
      const payload = {
        data: {
          id: auth.loggedInUser._id,
          address: newAddress,
          country: newCountry.label,
          state: newState.label,
          district: newDistrict.label,
          pincode: newPincode,
        },
        onSuccess: () => {
          setEditMode(false);
          const payload = {
            id: auth.loggedInUser._id,
          };
          dispatch(actions.fetchProfileDetails(payload));
          toast.success("Address has been updated successfully", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
      };
      if (fileToUpload) payload.data.addressProof = fileToUpload;
      dispatch(actions.updateAddressDetails(payload));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files;
    setFileToUplopad(file);
  };
  const handleInputClick = () => {
    document.getElementById("input_file").click();
  };

  const handleAddressChange = (e) => {
    setNewAddress(e.target.value);
  };

  const handleCountryChange = (e, value) => {
    setNewCountry(value);
  };

  const handleStateChange = (e, value) => {
    setNewState(value);
  };

  const handleDistrictChange = (e, value) => {
    setNewDistrict(value);
  };

  const handlePincodeChange = (e, value) => {
    setNewPincode(e.target.value);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleDownloadAddressProof = () => {
    const payload = {
      addressProofUrl,
      fileName: "address_proof.pdf",
    };
    dispatch(actions.downloadAddressProof(payload));
  };

  if (!editMode) {
    return (
      <>
        <Col lg={10}>
          <h5>Address details</h5>
        </Col>
        <div
          style={{
            width: "32px",
            cursor: "pointer",
            position: "absolute",
            right: "24px",
            top: "20px",
          }}
          onClick={handleEditMode}
        >
          <div
            style={{
              width: "24px",
            }}
          >
            <img src={editIcon} alt="edit" style={{ width: "100%" }} />
          </div>
        </div>
        <Col lg={12} className="mt-2">
          <Form.Group controlId="dos">
            <Form.Label>
              <span className="headingText">Address</span>
            </Form.Label>
            <h6>{newAddress}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-3">
          <Form.Group controlId="doh">
            <Form.Label>
              <span className="headingText">Country</span>
            </Form.Label>
            <h6>{newCountry.label}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-3">
          <Form.Group controlId="dop">
            <Form.Label>
              <span className="headingText">State</span>
            </Form.Label>
            <h6>{newState.label}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-3">
          <Form.Group controlId="sdo">
            <Form.Label>
              <span className="headingText">City</span>
            </Form.Label>
            <h6>{newDistrict.label}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-3">
          <Form.Group controlId="sdo">
            <Form.Label>
              <span className="headingText">PIN code</span>
            </Form.Label>
            <h6>{newPincode}</h6>
          </Form.Group>
        </Col>
        <Col lg={3} className="mt-3">
          <Form.Group controlId="sdo">
            <Form.Label>
              <span className="headingText">Address proof</span>
            </Form.Label>
            <div>
              {addressProofUrl ? (
                <button
                  style={{
                    textDecoration: "none",
                    color: "#38A169",
                    background: "none",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={handleDownloadAddressProof}
                >
                  address_proof.pdf
                </button>
              ) : (
                <button
                  style={{
                    textDecoration: "none",
                    color: "#38A169",
                    background: "none",
                    border: "none",
                    outline: "none",
                  }}
                >
                  No address proof found
                </button>
              )}
            </div>
          </Form.Group>
        </Col>
      </>
    );
  }

  return (
    <>
      <Col lg={10}>
        <h5>Address details</h5>
      </Col>
      <div
        style={{
          width: "32px",
          cursor: "pointer",
          position: "absolute",
          right: "24px",
          top: "20px",
        }}
        onClick={handleEditMode}
      >
        <div
          style={{
            width: "16px",
          }}
        >
          <img src={closeIcon} alt="edit" style={{ width: "100%" }} />
        </div>
      </div>
      <label style={{ padding: "8px 12px" }} htmlFor="address">
        Address
      </label>
      <Col lg={12}>
        <textarea
          name="address"
          style={{
            padding: "12px",
            width: "40%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
          rows="3"
          value={newAddress}
          onChange={handleAddressChange}
          required
        />
      </Col>

      <Col lg={3}>
        <SelectCountry
          countryName={newCountry.label}
          handleCountryChange={handleCountryChange}
        />
      </Col>
      <Col lg={3}>
        <SelectState
          country={newCountry}
          state={newState}
          handleStateChange={handleStateChange}
        />
      </Col>
      <Col lg={3}>
        <SelectCity
          state={newState}
          city={newDistrict}
          handleCityChange={handleDistrictChange}
        />
      </Col>
      <Col lg={3}>
        <label style={{ padding: "4px 0" }} htmlFor="pincode">
          PIN code
        </label>
        <input
          style={{
            padding: "8px 12px",
            width: "100%",
            outline: "none",
            border: "1px solid #d4d4d4",
            borderRadius: "6px",
          }}
          type="text"
          value={newPincode}
          onChange={handlePincodeChange}
          required
        />
      </Col>

      <div style={{ marginTop: "8px" }}>
        <Box style={containerStyle}>
          <input
            type="file"
            name=""
            id="input_file"
            style={{ display: "none" }}
            //ref={fileRef}
            onChange={handleFileChange}
            accept={".pdf"/*, .zip, .png, .jpeg*/}
          />
          <div style={{ display: "flex", gap: 2, marginLeft: "36px" }}>
            <div
              style={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Drag and drop files or
            </div>
            <div
              style={{
                color: "#38A169",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
              onClick={handleInputClick}
            >
              Browse
            </div>
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
            }}
          >
            Supported formats: JPEG, JPG, PNG, PDF
          </div>
        </Box>
        {fileToUpload && (
          <div style={{ marginTop: "8px" }}>
            <Box style={fileContainerStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 4 }}>
                  <div>
                    <img src={FileIcon} alt="file" />
                  </div>

                  <div
                    style={{
                      color: "#1A202C",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "2px",
                    }}
                  >
                    {fileToUpload[0].name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setFileToUplopad(null);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>

      <Col lg={6}></Col>
      <Col lg={6}>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            padding: "16px 0 8px 0",
          }}
        >
          <Button
            type="submit"
            text="Update"
            solid={true}
            buttonClick={handleSubmit}
          />
        </div>
      </Col>
    </>
  );
};

export default AddressDetail;

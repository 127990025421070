import React from "react";
import { Navbar, NavDropdown, Nav, Button, Badge } from "react-bootstrap";
import logo from "../../../../../assets/dashboardImages/dashboard_logo.svg";
import toggleBtn from "../../../../../assets/dashboardImages/menubar.svg";
import bell from "../../../../../assets/dashboardImages/HiBell.svg";
import avatar from "../../../../../assets/dashboardImages/image.svg";
import down from "../../../../../assets/dashboardImages/downa.svg";
import SImage from "../../../shared/Image";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/auth";

const DashboardHeader = ({ toggleSidebar }) => {
  const auth = useAuth();
  return (
    <Navbar
      expand="lg"
      style={{ position: "sticky" }}
      fixed="top"
      className={[styles.navBgDark, styles.padding2rem].join(" ")}
    >
      <Link className="navbar-brand" to="/">
        <SImage avatar={logo} />
      </Link>
      <Button className={styles.tooglebgbtn} onClick={toggleSidebar}>
        <SImage avatar={toggleBtn} />
      </Button>
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
      {/* <Navbar.Collapse id="basic-navbar-nav"> */}
      <Nav className="me-auto"></Nav>
      <Nav className={"ml-auto col-xs-1 "+styles.displayContent}>
        {/* <Link to="#" className={[styles.padding10per, "nav-link"].join(" ")}>
          <SImage avatar={bell} />
        </Link> */}
        <Link to="#" className={[styles.padding10per, "nav-link"].join(" ")}>
          <Badge className={styles.badgeBg}>
            {auth.loggedInUser.role === "super_admin"
              ? "Admin"
              : auth.loggedInUser.role === "buyer"
              ? "Buyer"
              : "Seller"}
          </Badge>
        </Link>
        <Link className="nav-link" to="#services">
          <NavDropdown
            title={
              <div className="d-flex align-items-center">
                <div className={styles.avatarContainer}>
                  <img src={avatar} alt="" />
                </div>
                <SImage avatar={down} pclass={styles.p_10_l} />
              </div>
            }
            id="basic-nav-dropdown"
          >
            {/* <button onClick={auth.logout}>Logout</button> */}
            <NavDropdown.Item onClick={auth.logout}>Logout</NavDropdown.Item>
            {/* <NavDropdown.Item to="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item to="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item to="#action/3.4">Separated link</NavDropdown.Item> */}
          </NavDropdown>
        </Link>
      </Nav>
      {/* </Navbar.Collapse> */}
    </Navbar>
  );
};

export default DashboardHeader;
